/* App bar */

// React
import React, { useState, useContext, useCallback, useEffect } from 'react';

// Notifications
import { NotificationManager } from 'react-notifications';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import PaletteIcon from '@material-ui/icons/Palette';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MenuDrawer from '../Menu';
import ChangePasswordDialog from '../ChangePasswordDialog';

// Utils
import request from '../../../utils/request';
import clsx from 'clsx';

//Constants
import { BACKEND_URL } from '../../../constants';

//Actions
import { changeTheme, logout, logoutFail, logoutSuccess } from '../../App/actions';

// Context
import { AuthContext, SocketContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
    color: theme.palette.text.primary,

    '&$driversInDanger': {
      background: '#cf1f24'
    }
  },
  toolbar: {
    justifyContent: 'flex-between'
  },
  driversInDangerContainer: {
    flex: 1,
    textAlign: 'center',
    fontSize: 16
  },
  driversInDanger: {},
  menuButton: {
    marginRight: theme.spacing(2)
  },
  listIcon: {
    minWidth: 40
  },
  userMenuButton: {
    textTransform: 'none'
  },
  connectionIndicator: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    marginRight: 10
  },
  connectedIndicator: {
    backgroundColor: theme.statusPalette.connected,
    boxShadow: '0px 0px 3px #0d701c'
  },
  disconnectedIndicator: {
    backgroundColor: theme.statusPalette.disconnected,
    boxShadow: '0px 0px 3px #8f0a0a'
  },
  connectedText: {
    color: theme.statusPalette.connected,
    fontWeight: 'bold'
  },
  disconnectedText: {
    color: theme.statusPalette.disconnected,
    fontWeight: 'bold'
  },
  username: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 120
  },
  themeItem: {
    paddingTop: 0,
    paddingBottom: 0,

    '&:focus': {
      outline: 'none'
    }
  }
}));

const AppHeaderBar = () => {
  const { dispatch, username, interior, theme } = useContext(AuthContext);

  const [driversInDanger, setDriversInDanger] = useState([]);

  const [darkTheme, setDarkTheme] = useState(theme === 'dark');

  const { socket, connected } = useContext(SocketContext);

  const classes = useStyles();

  const [menuOpened, setMenuOpened] = useState(false);

  const [dropdownEl, setDropdownEl] = useState(null);

  const [isPasswordDialogOpen, setPasswordDialogOpen] = useState(false);

  const updateDriversInDanger = useCallback(
    ({ drivers }) => {
      setDriversInDanger(drivers.map(({ indicative }) => indicative));
    },
    [setDriversInDanger]
  );

  const addDriverInDanger = useCallback(
    ({ indicative }) => {
      setDriversInDanger((oldDrivers) => [...oldDrivers, indicative]);
    },
    [setDriversInDanger]
  );

  const removeDriverInDanger = useCallback(
    ({ indicative }) => {
      setDriversInDanger((oldDrivers) => oldDrivers.filter((d) => d !== indicative));
    },
    [setDriversInDanger]
  );

  // Trigger drivers get on load
  useEffect(() => {
    if (socket && connected) {
      socket.send({
        event: 'dispatchers.drivers.panic.get'
      });

      socket.on('dispatchers.drivers.panic', updateDriversInDanger);
      socket.on('dispatchers.drivers.panic.start', addDriverInDanger);
      socket.on('dispatchers.drivers.panic.stop', removeDriverInDanger);

      return () => {
        if (socket && connected) {
          socket.off('dispatchers.drivers.panic', updateDriversInDanger);
          socket.off('dispatchers.drivers.panic.start', addDriverInDanger);
          socket.off('dispatchers.drivers.panic.stop', removeDriverInDanger);
        }
      };
    }
  }, [socket, connected, updateDriversInDanger, addDriverInDanger, removeDriverInDanger]);

  const handleThemeChange = useCallback(() => {
    setDarkTheme((oldValue) => {
      const newTheme = oldValue ? 'light' : 'dark';
      dispatch(changeTheme(newTheme));
      localStorage.setItem('theme', newTheme);
      return newTheme === 'dark';
    });
  }, [dispatch, setDarkTheme]);

  const handleDropdownClick = (event) => {
    setDropdownEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropdownEl(null);
  };

  const handleChangePasswordClick = () => {
    setDropdownEl(null);
    setPasswordDialogOpen(true);
  };

  const handleLogout = async () => {
    try {
      dispatch(logout());

      await request(`${BACKEND_URL}/user/logout`, {
        method: 'POST'
      });

      dispatch(logoutSuccess());
      localStorage.removeItem('token');
    } catch (ex) {
      dispatch(logoutFail());
      NotificationManager.error(`A apărut o eroare la deconectare ${ex.message}`);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        color="inherit"
        classes={{ root: clsx(classes.appbar, driversInDanger.length && classes.driversInDanger) }}
      >
        <Toolbar classes={{ root: classes.toolbar }}>
          <Box display="flex">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="meniu"
              onClick={() => setMenuOpened(true)}
            >
              <MenuIcon />
            </IconButton>

            {connected ? (
              <Tooltip title="Conectat!">
                <Box display="flex" alignItems="center" className={classes.connectedText}>
                  <span className={clsx(classes.connectionIndicator, classes.connectedIndicator)} />
                  {interior || 'Fără interior'}
                </Box>
              </Tooltip>
            ) : (
              <Box display="flex" alignItems="center" className={classes.disconnectedText}>
                <span className={clsx(classes.connectionIndicator, classes.disconnectedIndicator)} />
                Deconectat
              </Box>
            )}
          </Box>

          <div className={classes.driversInDangerContainer}>
            {!!driversInDanger.length && <span>Șoferi în pericol: {driversInDanger.join(', ')}</span>}
          </div>

          <Button
            onClick={handleDropdownClick}
            endIcon={<PersonIcon />}
            classes={{ root: classes.userMenuButton }}
            aria-label="opțiuni utilizator"
            aria-controls="dropdown-menu"
            aria-haspopup="true"
          >
            <span className={classes.username}>{username}</span>
          </Button>

          <Menu
            id="dropdown-menu"
            getContentAnchorEl={null}
            anchorEl={dropdownEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            open={!!dropdownEl}
            onClose={handleDropdownClose}
          >
            <ListItem component="div" classes={{ root: classes.themeItem }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <PaletteIcon fontSize="small" />
              </ListItemIcon>

              <ListItemText>
                Temă întunecată <Switch checked={darkTheme} onChange={handleThemeChange} />
              </ListItemText>
            </ListItem>

            <MenuItem onClick={handleChangePasswordClick} aria-label="Schimbă parola">
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <VpnKeyIcon fontSize="small" />
              </ListItemIcon>

              <ListItemText primary="Schimbă parola" />
            </MenuItem>

            <MenuItem onClick={handleLogout} aria-label="Delogare">
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>

              <ListItemText primary="Delogare" />
            </MenuItem>
          </Menu>

          {isPasswordDialogOpen && <ChangePasswordDialog closeDialog={() => setPasswordDialogOpen(false)} />}
        </Toolbar>
      </AppBar>

      <MenuDrawer isOpened={menuOpened} setIsOpened={setMenuOpened} />
    </div>
  );
};

export default AppHeaderBar;
