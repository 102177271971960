// Left side menu

// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import BarChart from '@material-ui/icons/BarChart';
import Storage from '@material-ui/icons/Storage';
import Dashboard from '@material-ui/icons/Dashboard';
import Map from '@material-ui/icons/Map';
import Person from '@material-ui/icons/Person';
import EditLocation from '@material-ui/icons/EditLocation';
import Group from '@material-ui/icons/Group';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import SettingsIcon from '@material-ui/icons/Settings';
import CallIcon from '@material-ui/icons/Call';
import { makeStyles } from '@material-ui/core/styles';

// Constants
import {
  DASHBOARD_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  MAP_ROUTE,
  ORDERS_HISTORY_ROUTE,
  STATISTICS_ROUTE,
  USERS_ROUTE,
  SETTINGS_ROUTE,
  CLIENTS_ROUTE,
  ADDRESSES_ROUTE,
  DEMO_CALLS_ROUTE
} from '../../Routes/constants';
import {
  MANAGE_CONFIG_PERMISSION,
  MANAGE_USERS_PERMISSION,
  READ_USERS_PERMISSION,
  READ_STATISTICS_PERMISSION,
  ORDERS_OVERVIEW_PERMISSION,
  checkPermissions
} from '../../../utils/permissions';

// Components
import CustomMenuItem from './CustomMenuItem';

// Utils
import { AuthContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    width: 240,
    flexDirection: 'column'
  },
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

let menuItems = [
  {
    text: 'Dispecerat',
    Icon: Dashboard,
    route: DASHBOARD_ROUTE
  },
  {
    text: 'Harta mare',
    Icon: Map,
    route: MAP_ROUTE
  },
  {
    text: 'Admin',
    Icon: Storage,
    route: ADMIN_DASHBOARD_ROUTE,
    permissions: [ORDERS_OVERVIEW_PERMISSION]
  },
  {
    text: 'Utilizatori',
    Icon: Group,
    route: USERS_ROUTE,
    permissions: [READ_USERS_PERMISSION, MANAGE_USERS_PERMISSION]
  },
  {
    text: 'Clienți',
    Icon: Person,
    route: CLIENTS_ROUTE
  },
  {
    text: 'Adrese',
    Icon: EditLocation,
    route: ADDRESSES_ROUTE
  },
  {
    text: 'Statistici',
    Icon: BarChart,
    route: STATISTICS_ROUTE,
    permissions: [READ_STATISTICS_PERMISSION]
  },
  {
    text: 'Arhivă comenzi',
    Icon: LocalTaxiIcon,
    route: ORDERS_HISTORY_ROUTE
  },
  {
    text: 'Setări',
    Icon: SettingsIcon,
    route: SETTINGS_ROUTE,
    permissions: [MANAGE_CONFIG_PERMISSION]
  }
];

if (process.env.REACT_APP_ENV === 'dev') {
  menuItems.splice(1, 0, {
    text: 'Simulare centrală',
    Icon: CallIcon,
    route: DEMO_CALLS_ROUTE
  });
}

const Menu = ({ isOpened, setIsOpened }) => {
  const classes = useStyles();

  const { permissions } = useContext(AuthContext);

  return (
    <Drawer
      variant="temporary"
      open={isOpened}
      onClose={() => setIsOpened(false)}
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper
      }}
    >
      <List classes={{ root: classes.list }} component="div" onClick={() => setIsOpened(false)}>
        {menuItems.map(({ permissions: menuPermissions, ...props }, i) => {
          if (menuPermissions && !checkPermissions(permissions, menuPermissions)) {
            return null;
          }

          return <CustomMenuItem key={i} {...props} />;
        })}
      </List>
    </Drawer>
  );
};

Menu.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired
};

export default Menu;
