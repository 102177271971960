/** Modal used to view chat history for a specific order */

// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';

// Utils
import { useFetch } from '../../../utils/request';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import clsx from 'clsx';

// Constants
import { BACKEND_URL } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
    background: theme.table.headerBackground,
    textAlign: 'center'
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  date: {},
  identifier: {},
  message: {
    minWidth: 100,
    borderRadius: 8,
    position: 'relative',
    padding: '15px 40px 10px 10px',
    wordBreak: 'break-all',

    '& $date': {
      position: 'absolute',
      right: 3,
      bottom: 0,
      fontSize: 11,
      opacity: 0.5
    },

    '& $identifier': {
      position: 'absolute',
      left: 3,
      top: 0,
      fontSize: 11,
      opacity: 0.5
    }
  },
  clientMessage: {
    border: '1px solid #b9abff'
  },
  driverMessage: {
    border: '1px solid #ffa12c'
  }
}));

function ChatHistoryDialog({ onClose, orderId }) {
  const classes = useStyles();

  const { loading, data, error } = useFetch(`${BACKEND_URL}/orders/${orderId}/chat/history`);

  useEffect(() => {
    if (!loading) {
      if (error) {
        NotificationManager.error(`A apărut o eroare: ${error}`);
      } else if (!data?.messages?.length) {
        NotificationManager.warning(`Conversație inexistentă`);
      }
    }
  }, [loading, error, data]);

  if (loading) {
    return <OverlayLoader fullScreen />;
  }

  if (error || !data?.messages?.length) {
    return null;
  }

  const { messages, indicative, phone } = data;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose} aria-labelledby="order-details">
      <DialogTitle id="order-details" classes={{ root: classes.title }}>
        Conversație
        <IconButton onClick={onClose} classes={{ root: classes.closeBtn }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {messages.map(({ id, created_at, message, message_by_driver }) =>
          message_by_driver ? (
            <Box key={id} display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
              <div className={clsx(classes.message, classes.driverMessage)}>
                {message}
                <span className={classes.identifier}>{indicative}</span>
                <span className={classes.date}>{moment(created_at).format('HH:mm')}</span>
              </div>
            </Box>
          ) : (
            <Box key={id} display="flex" justifyContent="flex-start" alignItems="center" mb={1}>
              <div className={clsx(classes.message, classes.clientMessage)}>
                {message}
                <span className={classes.identifier}>{phone}</span>
                <span className={classes.date}>{moment(created_at).format('HH:mm')}</span>
              </div>
            </Box>
          )
        )}
      </DialogContent>
    </Dialog>
  );
}

ChatHistoryDialog.propTypes = {
  orderId: PropTypes.string.isRequired
};

export default ChatHistoryDialog;
