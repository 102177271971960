const eventsKey = Symbol('events');

var indexOf;

if (typeof Array.prototype.indexOf === 'function') {
  indexOf = function (haystack, needle) {
    return haystack.indexOf(needle);
  };
} else {
  indexOf = function (haystack, needle) {
    var i = 0,
      length = haystack.length,
      idx = -1,
      found = false;

    while (i < length && !found) {
      if (haystack[i] === needle) {
        idx = i;
        found = true;
      }

      i++;
    }

    return idx;
  };
}

class WsClient {
  constructor(url) {
    this[eventsKey] = {};
    this.url = url;

    try {
      this.createNewConnection();
    } catch (e) {
      throw new Error(`Socket connection error: ${e.message}`);
    }

    return this;
  }

  createNewConnection() {
    this.socket = new window.WebSocket(this.url);
    this.setup();
  }

  setup() {
    this.socket.onopen = (e) => this.emit('open', e);

    this.socket.onmessage = (e) => {
      let lines = e.data.split(/\r?\n/);
      for (let i = 0; i < lines.length; i++) {
        const data = JSON.parse(lines[i]);
        this.emit(data.event, data);
      }
    };

    this.socket.onerror = (e) => {
      this.emit('error', e);
    };

    this.socket.onclose = (e) => {
      this.emit('close', e);

      if (e.code !== 4000 && e.code !== 1000) {
        setTimeout(() => {
          this.createNewConnection();
        }, 3000);
      }
    };
  }

  close() {
    if (this.socket && this.socket.OPEN) {
      this.socket.close(1000);
    }
  }

  send(data) {
    if (this.socket && this.socket.OPEN) {
      this.socket.send(typeof data === 'object' ? JSON.stringify(data) : data);
    }
  }

  on(event, listener) {
    if (typeof this[eventsKey][event] !== 'object') {
      this[eventsKey][event] = [];
    }

    this[eventsKey][event].push(listener);
  }

  once(event, listener) {
    this.on(event, function g() {
      this.off(event, g);
      listener.apply(this, arguments);
    });
  }

  off(event, listener) {
    var idx;

    if (typeof this[eventsKey][event] === 'object') {
      idx = indexOf(this[eventsKey][event], listener);

      if (idx > -1) {
        this[eventsKey][event].splice(idx, 1);
      }
    }
  }

  addListener(event, listener) {
    this.on(event, listener);
  }

  removeListener(event, listener) {
    this.off(event, listener);
  }

  emit(event) {
    const args = [].slice.call(arguments, 1);

    if (typeof this[eventsKey][event] === 'object') {
      const listeners = this[eventsKey][event].slice();

      for (let i = 0; i < listeners.length; i++) {
        listeners[i].apply(this, args);
      }
    }
  }
}

export default Object.seal(WsClient);
