// Order form validation

import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  address: yup.string().trim().required('Câmp obligatoriu').max(255, 'Lungime maximă 255 caractere'),
  latitude: yup.number().required('Adresa este invalidă'),
  longitude: yup.number().required('Adresa este invalidă'),
  name: yup.string().max(255, 'Lungime maximă 255 caractere'),
  description: yup.string().max(255, 'Lungime maximă 255 caractere'),
  details: yup.string().max(255, 'Lungime maximă 255 caractere'),
  nr_of_cars: yup.number().required('Câmp obligatoriu').min(1, 'Minim 1 mașină').max(255, 'Maxim 255 mașini'),
  non_smoker: yup.bool(),
  big_luggage: yup.bool(),
  with_animals: yup.bool()
});
