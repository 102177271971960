/* Global constants */

export const BACKEND_URL = (() => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://backend.mobyltaxihunedoara.ro/api';
  }

  return 'https://backend.hunedoara.rambit.ro/api';
})();

export const SOCKET_URL = (() => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return 'wss://backend.mobyltaxihunedoara.ro:6062/ws/dispatchers';
  }

  return 'wss://backend.hunedoara.rambit.ro:6062/ws/dispatchers';
})();

// Field validation
export const USERNAME_MAX_LENGTH = 255;
export const PASSWORD_MIN_LENGTH = 4;
export const PASSWORD_MAX_LENGTH = 50;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$/;

// Calls statuses
export const ANSWERED_STATUS = 'ANSWERED';
export const NEW_STATUS = 'NEW';
export const HOLDED_STATUS = 'HOLDED';

// Hunedoara's center
export const DEFAULT_MAP_CENTER = {
  lat: 45.76314936490892,
  lng: 22.90999688085172
};

// Order cancellation reasons
export const CANCELED_DISPATCHER = 'CANCELED_DISPATCHER';
export const CANCELED_DRIVER = 'CANCELED_DRIVER';
export const WRONG_ORDER = 'WRONG_ORDER';
export const NO_DRIVERS_FOUND = 'NO_DRIVERS_FOUND';
export const CANCELED_DRIVER_NO_CUSTOMER = 'CANCELED_DRIVER_NO_CUSTOMER';
export const CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER';

export const REASON_MESSAGES = {
  [CANCELED_DISPATCHER]: 'Anulată',
  [WRONG_ORDER]: 'Greșită',
  [NO_DRIVERS_FOUND]: 'Mașină lipsă',
  [CANCELED_DRIVER]: 'Refuzată',
  [CANCELED_DRIVER_NO_CUSTOMER]: 'Client lipsă',
  [CANCELED_BY_CUSTOMER]: 'Anulată client'
};

// Other
export const DEFAULT_THEME = 'dark';
export const TOKEN_REFRESH_INTERVAL = 23 * 60 * 60 * 1000;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
