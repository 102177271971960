/* App action creators */

// Constants
import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, CHANGE_THEME, LOGOUT_SUCCESS, LOGOUT_FAIL } from './constants';

export const login = () => ({
  type: LOGIN
});

export const loginSuccess = ({ permissions, username, email, interior }) => ({
  type: LOGIN_SUCCESS,
  permissions,
  username,
  email,
  interior
});

export const loginFail = () => ({
  type: LOGIN_FAIL
});

export const logout = () => ({
  type: LOGOUT
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

export const logoutFail = () => ({
  type: LOGOUT_FAIL
});

export const changeTheme = (theme) => ({
  type: CHANGE_THEME,
  theme
});
