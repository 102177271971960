// React
import React, { useContext, useEffect, useState, useCallback } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

// Components
import TabPanel from '../../TabPanel';
import OrderForm from './OrderForm';

// Utils
import { SocketContext } from '../../../context';
import { LocationContext } from '../../Providers/LocationProvider';
import { NotificationManager } from 'react-notifications';
import clsx from 'clsx';
import findIndex from 'lodash/findIndex';
import { v4 as uuid } from 'uuid';

// Constants
import { ANSWERED_STATUS, HOLDED_STATUS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  tabsHeader: {
    background: theme.table.headerBackground
  },
  tab: {
    minWidth: 'auto',
    maxWidth: 'auto'
  },
  [ANSWERED_STATUS]: {
    '&&': {
      color: theme.statusPalette[ANSWERED_STATUS],
      fontWeight: 'bold'
    }
  },
  [HOLDED_STATUS]: {
    '&&': {
      color: theme.statusPalette[HOLDED_STATUS],
      fontWeight: 'bold'
    }
  },
  closeBtn: {
    marginLeft: 10,
    fontWeight: 'bold',
    background: 'transparent',
    outline: 'none',
    border: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'all 300ms',

    '&:hover': {
      color: theme.statusPalette.disconnected
    }
  }
}));

function OrdersTabs() {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const [calls, setCalls] = useState([]);

  const { socket, connected } = useContext(SocketContext);
  const { setUpdatedFromMap } = useContext(LocationContext);

  const duplicateCall = useCallback(
    (call) => {
      setCalls((callsArray) => [...callsArray, { uid: uuid(), ...call }]);
    },
    [setCalls]
  );

  const loadCalls = useCallback(
    ({ calls }) => {
      const activeCalls = calls.filter(({ status }) => status === ANSWERED_STATUS || status === HOLDED_STATUS);

      setCalls(
        activeCalls.map(({ customer: { id, phone, name, description }, line, status, orders }) => ({
          uid: uuid(),
          id,
          name,
          phone,
          description,
          line,
          orders,
          status
        }))
      );
    },
    [setCalls]
  );

  const answerCall = useCallback(
    ({ customer: { id, name, phone, description }, line, orders }) => {
      setCalls((callsArray) => {
        const index = findIndex(callsArray, { id });
        const newCall = {
          uid: uuid(),
          id,
          name,
          phone,
          description,
          line,
          orders,
          status: ANSWERED_STATUS
        };
        const newArray = [...callsArray];

        if (index > -1) {
          newArray.splice(index, 1, newCall);
          setActiveTab(index + 1);
        } else {
          newArray.push(newCall);
          setActiveTab(callsArray.length + 1);
        }

        return newArray;
      });
    },
    [setCalls]
  );

  const updateCall = useCallback(
    (id, newStatus) => {
      setCalls((callsArray) =>
        callsArray.map((call) => {
          if (call.id === id) {
            return {
              ...call,
              status: newStatus
            };
          }

          return call;
        })
      );
    },
    [setCalls]
  );

  const holdCall = useCallback(
    ({ customer: { id } }) => {
      updateCall(id, HOLDED_STATUS);
    },
    [updateCall]
  );

  const unholdCall = useCallback(
    ({ customer: { id } }) => {
      updateCall(id, ANSWERED_STATUS);
    },
    [updateCall]
  );

  const handleOrderCreateSuccess = useCallback(
    ({ ui_id }) => {
      if (ui_id) {
        setActiveTab(0);
        setCalls((callsArray) => callsArray.filter(({ uid }) => uid !== ui_id));
      }

      NotificationManager.success('Comandă adăugată cu success!');
    },
    [setActiveTab, setCalls]
  );

  const handleOrderCreateError = useCallback(({ error }) => {
    NotificationManager.error(error);
  }, []);

  useEffect(() => {
    if (connected && socket) {
      socket.on('duplicate.call', duplicateCall);
      socket.on('dispatchers.customers.calls.active', loadCalls);
      socket.on('dispatchers.phone.call.answered', answerCall);
      socket.on('dispatchers.phone.call.holded', holdCall);
      socket.on('dispatchers.phone.call.answered_from_hold', unholdCall);
      socket.on('dispatchers.orders.create.success', handleOrderCreateSuccess);
      socket.on('dispatchers.orders.create.error', handleOrderCreateError);
    }

    return () => {
      if (connected && socket) {
        socket.off('duplicate.call', duplicateCall);
        socket.off('dispatchers.customers.calls.active', loadCalls);
        socket.off('dispatchers.phone.call.answered', answerCall);
        socket.off('dispatchers.phone.call.holded', holdCall);
        socket.off('dispatchers.phone.call.answered_from_hold', unholdCall);
        socket.off('dispatchers.orders.create.success', handleOrderCreateSuccess);
        socket.off('dispatchers.orders.create.error', handleOrderCreateError);
      }
    };
  }, [
    socket,
    connected,
    loadCalls,
    answerCall,
    holdCall,
    unholdCall,
    handleOrderCreateSuccess,
    handleOrderCreateError,
    duplicateCall
  ]);

  const handleChange = (event, newValue) => {
    // reset updatedFromMap flag - changing the tab shouldn't affect the autocomplate value
    setUpdatedFromMap(false);
    setActiveTab(newValue);
  };

  return (
    <Paper classes={{ root: classes.paper }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        classes={{ root: classes.tabsHeader }}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab classes={{ root: classes.tab }} label="Nouă comandă" />

        {calls.map(({ uid, line, status, phone }, tabIndex) => (
          <Tab
            key={uid}
            classes={{ root: clsx(classes.tab, classes[status]) }}
            label={
              <Box display="flex" alignItems="center">
                {phone}
                <ClearIcon
                  className={classes.closeBtn}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (activeTab === tabIndex + 1) {
                      setActiveTab(0);
                    } else if (activeTab > tabIndex + 1) {
                      setActiveTab(activeTab - 1);
                    }
                    setCalls((callsArray) => callsArray.filter((call) => call.uid !== uid));
                  }}
                />
              </Box>
            }
          />
        ))}
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <OrderForm hideDetails isActive={activeTab === 0} />
      </TabPanel>

      {calls.map(({ uid, id, name, phone, description, line, orders }, index) => (
        <TabPanel key={uid} value={activeTab} index={index + 1}>
          <OrderForm
            isActive={activeTab === index + 1}
            uuid={uid}
            clientId={id}
            clientName={name}
            clientDescription={description}
            clientPhone={phone}
            line={line}
            orders={orders}
          />
        </TabPanel>
      ))}
    </Paper>
  );
}

export default OrdersTabs;
