/* Address form */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// Components
import CustomTextField from '../../Form/CustomTextField';

// Formik
import { Form } from 'formik';

// Utils
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 4
  },
  addressInput: {
    flex: 2,
    marginRight: 2
  },
  latitudeInput: {
    flex: 1,
    marginLeft: 2,
    marginRight: 2
  },
  longitudeInput: {
    flex: 1,
    marginLeft: 2
  },
  numericInput: {
    '& input': {
      /* Firefox */
      '-moz-appearance': 'textfield'
    },

    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  resetBtn: {
    marginRight: 4
  },
  addressInEdit: {
    color: theme.statusPalette.active,
    fontSize: 13
  }
}));

const AddressForm = ({ isSubmitting, dirty, isValid, resetForm, addressInEdit }) => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <Form>
        <Box display="flex">
          <CustomTextField
            label="Adresa"
            name="address"
            variant="outlined"
            size="small"
            classes={{ root: clsx(classes.input, classes.addressInput) }}
          />

          <CustomTextField
            type="number"
            label="Latitudine"
            name="latitude"
            variant="outlined"
            size="small"
            classes={{ root: clsx(classes.numericInput, classes.latitudeInput) }}
          />

          <CustomTextField
            type="number"
            label="Longitudine"
            name="longitude"
            variant="outlined"
            size="small"
            classes={{ root: clsx(classes.numericInput, classes.longitudeInput) }}
          />
        </Box>

        <Box display="flex" alignItems="center">
          {addressInEdit && <span className={classes.addressInEdit}>Adresa în editare: {addressInEdit}...</span>}

          <Box display="flex" flex={1} justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              classes={{ root: classes.resetBtn }}
              disabled={!dirty || isSubmitting}
              onClick={() => {
                resetForm();
              }}
            >
              Resetare
            </Button>

            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!dirty || isSubmitting || !isValid}
              type="submit"
            >
              Salvează
            </Button>
          </Box>
        </Box>
      </Form>
    </Paper>
  );
};

AddressForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  addressInEdit: PropTypes.string
};

export default AddressForm;
