/* Dispatcher dashboard */

// React
import React, { useContext, useEffect } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Components
import ActiveOrders from '../Orders/ActiveOrders';
import InactiveOrders from '../Orders/InactiveOrders';
import OrdersTabs from '../Orders/OrdersTabs';
import CallsList from '../CallsList';
import SmallMap from '../Map/SmallMap';
import OverlayLoader from '../../Loader/OverlayLoader';

// Utils
import { AuthContext, SocketContext } from '../../../context';
import { NotificationManager } from 'react-notifications';
import { ACCEPTED, CANCELED, COMPLETED, DELAYED_TAKEN } from '../../Routes/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  firstRow: {
    height: '70%'
  },
  secondRow: {
    height: '30%'
  }
}));

const displayNotifications = ({ message, status }) => {
  switch (status) {
    case CANCELED:
      NotificationManager.error(message, undefined, 7000, undefined, true);
      break;
    case ACCEPTED:
      NotificationManager.success(message, undefined, 7000, undefined, true);
      break;
    case COMPLETED:
      NotificationManager.info(message);
      break;
    case DELAYED_TAKEN:
      NotificationManager.warning(message);
      break;
    default:
      break;
  }
};

const DispatcherDashboard = () => {
  const classes = useStyles();

  const { interior, loggingOut } = useContext(AuthContext);

  const { retries, socket, connected } = useContext(SocketContext);

  // Trigger orders get on load
  useEffect(() => {
    if (socket && connected) {
      socket.on('dispatchers.notification', displayNotifications);

      socket.send({
        event: 'dispatchers.orders.get'
      });

      socket.send({
        event: 'dispatchers.customers.calls.active.get'
      });
    }

    return () => {
      if (socket && connected) {
        socket.off('dispatchers.notification', displayNotifications);
      }
    };
  }, [socket, connected]);

  return (
    <Box className={classes.root} position="relative">
      {!loggingOut && !connected && (
        <OverlayLoader text={`Se conectează...${retries > 1 ? `(reîncercarea ${retries})` : ''}`} />
      )}

      <Box display="flex" className={classes.firstRow}>
        {!!interior && (
          <Box width={250} p={1}>
            <CallsList />
          </Box>
        )}

        <Box width={interior ? 'calc(100% - 250px)' : '100%'} display="flex">
          <Box width="70%" p={1} display="flex" flexDirection="column">
            <OrdersTabs />

            <Box flex={1} overflow="hidden">
              <ActiveOrders />
            </Box>
          </Box>

          <Box width="30%" p={1} display="flex">
            <Box position="relative" flex={1}>
              <SmallMap />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.secondRow} p={1} overflow="hidden">
        <InactiveOrders />
      </Box>
    </Box>
  );
};

export default DispatcherDashboard;
