/* Statistics view */

// React
import React, { useCallback, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import { useFetch } from '../../../utils/request';
import getQueryString from '../../../utils/getQueryString';
import moment from 'moment';
import omit from 'lodash/omit';

// Components
import DatetimeRangePicker from '../../DateTimePicker';
import OverlayLoader from '../../Loader/OverlayLoader';
import PieChart from './PieChart';
import LineChart from './LineChart';

// Constants
import { BACKEND_URL, DATE_FORMAT } from '../../../constants';
import { ACCEPTED, CANCELED, PENDING } from '../../Routes/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    '& .apexcharts-align-left': {
      textAlign: 'left'
    },

    '& .apexcharts-tooltip-text-label': {
      textShadow: '0px 0px 3px #000000'
    },

    '& .apexcharts-tooltip-text-value': {
      textShadow: '0px 0px 3px #000000'
    }
  },
  periodicitySelect: {
    margin: theme.spacing(1)
  },
  legend: {
    left: theme.spacing(3),
    top: theme.spacing(3),

    '& span': {
      display: 'inline-block',
      borderRadius: '50%',
      width: 15,
      height: 15,
      marginRight: theme.spacing(1),

      '&$completed': {
        background: theme.statusPalette[ACCEPTED]
      },

      '&$canceled': {
        background: theme.statusPalette[CANCELED]
      },

      '&$lost': {
        background: theme.statusPalette[PENDING]
      }
    }
  },
  completed: {},
  canceled: {},
  lost: {}
}));

const Statistics = () => {
  const classes = useStyles();

  const [dirty, setDirty] = useState(false);

  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));

  const [periodicity, setPeriodicity] = useState('day');

  const [filters, setFilters] = useState({
    q_created_at_start: moment().subtract(30, 'days').startOf('day').toISOString(),
    q_created_at_end: moment().endOf('day').toISOString(),
    q_group_by: 'day'
  });

  const { loading: pieLoading, data: pieData } = useFetch(
    `${BACKEND_URL}/statistics?${getQueryString(omit(filters, ['q_group_by']))}`
  );
  const { loading: lineLoading, data: lineData } = useFetch(
    `${BACKEND_URL}/statistics/expanded?${getQueryString(filters)}`
  );

  const handlePeriodicityChange = useCallback(
    (event) => {
      setDirty(true);
      setPeriodicity(event.target.value);
    },
    [setPeriodicity, setDirty]
  );

  const handleBtnClick = useCallback(() => {
    setFilters(() => ({
      q_created_at_start: startDate.toISOString(),
      q_created_at_end: endDate.toISOString(),
      q_group_by: periodicity
    }));
    setDirty(false);
  }, [setFilters, startDate, endDate, periodicity]);

  if (pieLoading || lineLoading) {
    return <OverlayLoader />;
  }

  return (
    <Paper classes={{ root: classes.paper }}>
      <Box display="flex" justifyContent="center" pb={4}>
        <DatetimeRangePicker
          timeFormat="HH:mm"
          dateFormat={DATE_FORMAT}
          defaultStartDate={startDate}
          startDate={startDate}
          defaultEndDate={endDate}
          endDate={endDate}
          startInputProps={{ placeholder: 'De la...' }}
          endInputProps={{ placeholder: 'până la...' }}
          onChange={({ start, end }) => {
            setDirty(true);
            setStartDate(start);
            setEndDate(end);
          }}
        />

        <Select
          className={classes.periodicitySelect}
          value={periodicity}
          onChange={handlePeriodicityChange}
          disableUnderline
        >
          <MenuItem value="day">Zilnic</MenuItem>
          <MenuItem value="month">Lunar</MenuItem>
          <MenuItem value="year">Anual</MenuItem>
        </Select>

        <Button size="small" disabled={!dirty} onClick={handleBtnClick}>
          Actualizează
        </Button>
      </Box>

      {pieData?.total ? (
        <Box flex={1} display="flex" flexDirection="column" alignItems="center" position="relative">
          <Box position="absolute" className={classes.legend}>
            <Box display="flex" alignItems="center">
              <span className={classes.completed} /> Comenzi complete
            </Box>

            <Box display="flex" alignItems="center">
              <span className={classes.canceled} /> Comenzi anulate
            </Box>

            <Box display="flex" alignItems="center">
              <span className={classes.lost} /> Comenzi pierdute
            </Box>
          </Box>

          <Box className={classes.chart} width="100%" maxWidth={220} textAlign="center">
            <PieChart data={pieData} />
          </Box>

          <Box width="100%" px={1}>
            <LineChart data={lineData} />
          </Box>
        </Box>
      ) : (
        <Box flex={1} display="flex" justifyContent="center" alignItems="center">
          Nu există comenzi pentru perioada selectată
        </Box>
      )}
    </Paper>
  );
};

export default Statistics;
