/* Order form */

// React
import React, { useContext, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Components
import CustomTextField from '../../Form/CustomTextField';
import Checkbox from '../../Form/Checkbox';
import AddressAutocomplete from './AddressAutocomplete';

// Utils
import { SocketContext } from '../../../context';
import { LocationContext } from '../../Providers/LocationProvider';
import { validationSchema } from './validation';
import { NotificationManager } from 'react-notifications';
import clsx from 'clsx';

// Formik
import { Form, Formik, Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  smallInput: {
    width: 90
  },
  input: {
    width: 'auto',
    flex: 1,
    marginRight: 5
  },
  btn: {
    marginLeft: 5
  },
  errorContainer: {
    color: theme.palette.error.main,
    fontSize: 13
  },
  checkbox: {
    color: theme.statusPalette.active,

    '&$checked': {
      color: theme.statusPalette.active
    }
  },
  checked: {},
  clientInput: {
    marginTop: 4
  },
  checkboxes: {
    marginTop: 4
  }
}));

const OrderForm = ({
  uuid,
  clientId,
  clientName = '',
  clientDescription = '',
  clientPhone,
  line,
  orders = [],
  hideDetails = false,
  isActive
}) => {
  const classes = useStyles();

  const { socket, connected } = useContext(SocketContext);

  const { location, setLocation, updatedFromMap } = useContext(LocationContext);

  const addressAutocompleteRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (updatedFromMap && isActive && location && formRef.current) {
      var geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            const { formatted_address } = results[0];

            const form = formRef.current;
            form.setValues({
              ...form.values,
              address: formatted_address,
              longitude: location.lng,
              latitude: location.lat,
              details: ''
            });
          } else {
            NotificationManager.warning('Adresă invalidă!');
          }
        } else {
          NotificationManager.warning('A apărut o eroare!');
        }
      });
    }
  }, [isActive, updatedFromMap, location]);

  useEffect(() => {
    if (addressAutocompleteRef.current && isActive) {
      addressAutocompleteRef.current.focus();
    }
  }, [isActive]);

  const handleOrderCreateError = useCallback(() => {
    const form = formRef.current;

    if (form) {
      form.setSubmitting(false);
    }
    setLocation(null);
  }, [setLocation]);

  const handleOrderCreateSuccess = useCallback(
    ({ customer_id }) => {
      const form = formRef.current;

      if (form) {
        // Only reset the main form
        if (!customer_id) {
          addressAutocompleteRef.current.focus();

          form.resetForm();
        }

        form.setSubmitting(false);
        setLocation(null);
      }
    },
    [setLocation]
  );

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.orders.create.success', handleOrderCreateSuccess);
      socket.on('dispatchers.orders.create.error', handleOrderCreateError);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.create.success', handleOrderCreateSuccess);
        socket.off('dispatchers.orders.create.error', handleOrderCreateError);
      }
    };
  }, [socket, connected, handleOrderCreateError, handleOrderCreateSuccess]);

  const initialValues = useMemo(
    () => ({
      address: '',
      latitude: '',
      longitude: '',
      name: clientName,
      description: clientDescription,
      details: '',
      nr_of_cars: 1,
      non_smoker: false,
      big_luggage: false,
      with_animals: false
    }),
    [clientName, clientDescription]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (socket && connected) {
        socket.send({
          event: 'dispatchers.orders.create',
          line,
          phone: clientPhone,
          id: clientId,
          ui_id: uuid,
          ...values
        });
      }
    },
    [socket, connected, clientPhone, line, clientId, uuid]
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, isValid, setValues, values, resetForm, errors }) => {
        const addressError = errors && (errors.latitude || errors.longitude);
        return (
          <Form>
            {addressError && <div className={classes.errorContainer}>{addressError}</div>}

            <Box display="flex">
              <AddressAutocomplete
                ref={addressAutocompleteRef}
                className={classes.input}
                setLocation={setLocation}
                resetForm={resetForm}
                formValues={values}
                setFormValues={setValues}
                clientId={clientId}
                clientAddresses={orders.map((adr) => ({
                  ...adr,
                  is_client: true
                }))}
              />

              <CustomTextField
                label="Detalii"
                name="details"
                variant="outlined"
                size="small"
                classes={{ root: classes.input }}
              />

              <CustomTextField
                label="Nr. mașini"
                name="nr_of_cars"
                variant="outlined"
                size="small"
                type="number"
                classes={{ root: classes.smallInput }}
                inputProps={{ min: 1, max: 255 }}
              />
            </Box>

            <Box display="flex">
              {!hideDetails && (
                <>
                  <CustomTextField
                    label="Nume client"
                    name="name"
                    variant="outlined"
                    size="small"
                    classes={{ root: clsx(classes.input, classes.clientInput) }}
                    margin="none"
                  />

                  <CustomTextField
                    label="Descriere client"
                    name="description"
                    variant="outlined"
                    size="small"
                    classes={{ root: clsx(classes.input, classes.clientInput) }}
                    margin="none"
                  />

                  <div className={classes.smallInput} />
                </>
              )}

              <Field name="address" type="hidden" />
              <Field name="latitude" type="hidden" />
              <Field name="longitude" type="hidden" />
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.checkboxes}>
              <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
                <Checkbox
                  name="non_smoker"
                  label="Mașină nefumători"
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                />
                <Checkbox
                  name="big_luggage"
                  label="Bagaje voluminoase"
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                />
                <Checkbox
                  name="with_animals"
                  label="Animale de companie"
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                />
              </Box>

              <Box>
                <Button
                  variant="contained"
                  classes={{ root: classes.btn }}
                  size="small"
                  disabled={!dirty || isSubmitting}
                  onClick={() => {
                    resetForm();
                    setLocation(null);
                    addressAutocompleteRef?.current && addressAutocompleteRef.current.focus();
                  }}
                >
                  Resetare
                </Button>

                <Button
                  variant="contained"
                  classes={{ root: classes.btn }}
                  size="small"
                  color="primary"
                  disabled={!dirty || isSubmitting || !isValid}
                  type="submit"
                >
                  Crează comanda
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

OrderForm.propTypes = {
  clientId: PropTypes.string,
  uuid: PropTypes.string,
  clientName: PropTypes.string,
  clientDescription: PropTypes.string,
  clientPhone: PropTypes.string,
  line: PropTypes.number,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      details: PropTypes.string,
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  hideDetails: PropTypes.bool,
  isActive: PropTypes.bool.isRequired
};

export default OrderForm;
