// React
import React, { useState, useMemo, useCallback, useContext } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import request, { useFetch } from '../../../utils/request';
import clsx from 'clsx';
import { NotificationManager } from 'react-notifications';
import { AuthContext } from '../../../context';
import moment from 'moment';

// Constants
import { BACKEND_URL, DATE_FORMAT, DATE_TIME_FORMAT } from '../../../constants';
import { MANAGE_USERS_PERMISSION } from '../../../utils/permissions';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';
import UserFormDialog from '../UserFormDialog';
import SearchField from '../../Form/SearchField';
import TablePaginationActions from '../../TablePaginationActions';
import ConfirmationModal from '../../ConfirmationDialog';
import PenaltyIcon from './PenaltyIcon';
import PenalizeDialog from '../Map/PenalizeDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  tableContainer: {
    width: '100%'
  },
  table: {
    minWidth: 1200
  },
  cell: {
    padding: theme.table.cellPadding,
    maxWidth: 300,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  dateCell: {
    width: 110,
    textAlign: 'center'
  },
  activeIcon: {
    fill: theme.statusPalette.active
  },
  inactiveIcon: {
    fill: theme.statusPalette.inactive
  },
  paginationToolbar: {
    overflow: 'hidden',
    minHeight: 52
  },
  addBtn: {
    marginLeft: 'auto'
  }
}));

export default function UsersList() {
  const classes = useStyles();

  const { permissions } = useContext(AuthContext);

  const canEditUser = permissions.includes(MANAGE_USERS_PERMISSION);
  const UserStatusComponent = canEditUser ? IconButton : 'div';

  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('rowsPerPage') || 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [userId, setUserId] = useState('-1');
  const [refresh, setRefresh] = useState(false);

  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [confirmActivateProps, setConfirmActivateProps] = useState(null);
  const [confirmDeleteProps, setConfirmDeleteProps] = useState(null);

  const [activeDriverProps, setActiveDriverProps] = useState(null);

  const { loading, data } = useFetch(
    `${BACKEND_URL}/users?page=${page}&limit=${rowsPerPage}&s_last_login_at=desc&q=${search}`,
    null,
    refresh
  );

  const columns = useMemo(
    () => [
      { id: 'username', label: 'Utilizator' },
      { id: 'name', label: 'Nume' },
      { id: 'forename', label: 'Prenume' },
      { id: 'car_phone_number', label: 'Telefon' },
      { id: 'email', label: 'Email' },
      {
        id: 'last_login_at',
        label: 'Ultima logare',
        format: (value) => (value ? moment(value).format(DATE_TIME_FORMAT) : '-')
      },
      {
        id: 'created_at',
        label: 'Creat la data',
        cls: classes.dateCell,
        format: (value) => moment(value).format(DATE_FORMAT)
      },
      { id: 'indicative', label: 'Indicativ', cls: classes.smallCell }
    ],
    [classes]
  );

  const activateUser = useCallback(
    async (id) => {
      try {
        const isActive = await request(`${BACKEND_URL}/users/${id}/toggle/active`, {
          method: 'POST'
        });

        setConfirmActivateProps(null);
        setRefresh((oldValue) => !oldValue);

        if (isActive) {
          NotificationManager.success(`Activat cu succes!`);
        } else {
          NotificationManager.success(`Dezactivat cu succes!`);
        }
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmActivateProps, setRefresh]
  );

  const deleteUser = useCallback(
    async (id) => {
      try {
        await request(`${BACKEND_URL}/users/${id}`, {
          method: 'DELETE'
        });

        // Reset page if there are no results and the page is not the first one
        if (data.data.length === 1 && page > 1) {
          setPage(1);
        } else {
          setRefresh((oldValue) => !oldValue);
        }

        setConfirmDeleteProps(null);
        NotificationManager.success(`Șters cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmDeleteProps, setRefresh, data, page, setPage]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage + 1);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      localStorage.setItem('rowsPerPage', +event.target.value);
      setRowsPerPage(+event.target.value);
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );

  const handleUserEdit = useCallback(
    (id) => {
      setUserId(id);
      setIsUserDialogOpen(true);
    },
    [setUserId, setIsUserDialogOpen]
  );

  return (
    <Paper classes={{ root: classes.paper }}>
      {loading && <OverlayLoader />}

      {isUserDialogOpen && (
        <UserFormDialog
          userId={userId}
          isDialogOpen={isUserDialogOpen}
          setIsDialogOpen={setIsUserDialogOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}

      {confirmActivateProps && (
        <ConfirmationModal onClose={() => setConfirmActivateProps(null)} {...confirmActivateProps} />
      )}

      {confirmDeleteProps && <ConfirmationModal onClose={() => setConfirmDeleteProps(null)} {...confirmDeleteProps} />}

      <Box display="flex" p={0.5} alignItems="center">
        <SearchField
          className={classes.search}
          handleSearch={(val) => {
            setSearch(val);
            setPage(1);
          }}
        />

        {canEditUser && (
          <Button color="primary" variant="outlined" className={classes.addBtn} onClick={() => handleUserEdit('-1')}>
            Adaugă utilizator
          </Button>
        )}
      </Box>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, cls, label, format, children, ...rest }) => (
                <TableCell key={id} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {children || label}
                </TableCell>
              ))}

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Penalizare
              </TableCell>

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Status
              </TableCell>

              {canEditUser && (
                <>
                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                    Editare
                  </TableCell>

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                    Ștergere
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.data.map((user) => (
                <TableRow key={user.id}>
                  {columns.map(({ id, format, cls }) => {
                    const value = user[id];

                    return (
                      <TableCell key={id} classes={{ root: clsx(classes.cell, cls) }}>
                        {format ? format(value, user) : value}
                      </TableCell>
                    );
                  })}

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <PenaltyIcon user={user} onClick={setActiveDriverProps} />
                  </TableCell>

                  <TableCell classes={{ root: classes.cell }}>
                    <UserStatusComponent
                      onClick={
                        canEditUser
                          ? () =>
                              setConfirmActivateProps({
                                confirmCallback: () => activateUser(user.id),
                                message: user.is_active
                                  ? `Dezactivezi utilizatorul ${user.username} ?`
                                  : `Activezi utilizatorul ${user.username} ?`
                              })
                          : undefined
                      }
                    >
                      {user.is_active ? (
                        <CheckCircleIcon className={classes.activeIcon} />
                      ) : (
                        <CancelIcon className={classes.inactiveIcon} />
                      )}
                    </UserStatusComponent>
                  </TableCell>

                  {canEditUser && (
                    <>
                      <TableCell classes={{ root: classes.cell }}>
                        <IconButton onClick={() => handleUserEdit(user.id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell classes={{ root: classes.cell }}>
                        <IconButton
                          onClick={() =>
                            setConfirmDeleteProps({
                              confirmCallback: () => deleteUser(user.id),
                              message: `Ștergi utilizatorul ${user.username} ?`
                            })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage="Rânduri pe pagină:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`}
        classes={{ root: classes.paginationToolbar }}
        component="div"
        count={data ? data.total : 0}
        rowsPerPage={rowsPerPage}
        page={data ? page - 1 : 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />

      {activeDriverProps && (
        <PenalizeDialog
          {...activeDriverProps}
          onClose={() => setActiveDriverProps(null)}
          saveCallback={() => setRefresh((oldValue) => !oldValue)}
        />
      )}
    </Paper>
  );
}
