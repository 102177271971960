// React
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomIcon from '@material-ui/icons/Room';
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import request from '../../../utils/request';
import { useDebouncedCallback } from 'use-debounce';
import deburr from 'lodash/deburr';
import { NotificationManager } from 'react-notifications';

// Constants
import { BACKEND_URL } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  clientAddress: {
    color: theme.statusPalette.clientAddress
  },
  customAddress: {
    color: theme.statusPalette.customAddress
  },
  googleAddress: {
    color: theme.palette.text.primary
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 20
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {
    paddingLeft: 4,
    paddingRight: 4
  },
  deleteIcon: {
    fontSize: '1.2rem'
  }
}));

const AddressAutocomplete = React.forwardRef(
  ({ clientAddresses, clientId, className, setLocation, setFormValues, formValues, resetForm }, ref) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(clientAddresses);
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState(null);
    const inputChangeReason = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const deleteAddress = useCallback(
      async (id) => {
        try {
          await request(`${BACKEND_URL}/customers/${clientId}/addresses/${id}/`, {
            method: 'DELETE'
          });

          setOptions((oldOptions) => oldOptions.filter((address) => address.id !== id));

          NotificationManager.success(`Ștersă cu succes!`);
        } catch (ex) {
          NotificationManager.error(`A apărut o eroare ${ex.message}`);
        }
      },
      [clientId, setOptions]
    );

    const requestSuggests = useDebouncedCallback(async (v, clientAddresses) => {
      try {
        const response = await request(`${BACKEND_URL}/addresses/all?q_address=${v}`);

        const customAddresses = response.custom_addresses.map((adr) => ({ ...adr, is_custom: true }));

        setOptions([...clientAddresses, ...customAddresses, ...response.google_addresses]);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      } finally {
        setLoading(false);
      }
    }, 500);

    useEffect(() => {
      const filteredClientAddresses = clientAddresses.filter(({ address }) =>
        deburr(address.toLowerCase()).includes(deburr(inputValue.toLowerCase()))
      );

      if (inputValue.length >= 3 && inputChangeReason.current === 'input') {
        requestSuggests(inputValue, filteredClientAddresses);
      } else {
        setOptions(filteredClientAddresses);
        setLoading(false);
      }
    }, [inputValue, clientAddresses, requestSuggests]);

    useEffect(() => {
      if (!formValues.address) {
        // form reset should also reset the autocomplete
        setValue(null);
      } else {
        // update from map should also update the value of the address input
        setValue({ address: formValues.address });
      }
    }, [formValues.address]);

    return (
      <Autocomplete
        className={className}
        classes={{ popper: classes.popper, option: classes.option }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        freeSolo
        openOnFocus
        clearText="Ștergere"
        filterOptions={(opts) => opts}
        noOptionsText="Nu există rezultate"
        loadingText="Se caută..."
        getOptionSelected={(option, value) => option.address === value.address}
        getOptionLabel={(option) => option.address}
        options={loading ? [] : options}
        loading={loading}
        value={value}
        onChange={(e, newAddress) => {
          setValue(newAddress);

          if (!newAddress) {
            resetForm();
            setLocation(null);
            return;
          }

          if (newAddress.place_id) {
            // Google address - geocode by place id in order to get the coordinates
            var geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ placeId: newAddress.place_id }, function (results, status) {
              if (status === 'OK') {
                if (results[0]) {
                  const {
                    geometry: { location }
                  } = results[0];
                  const latitude = location.lat();
                  const longitude = location.lng();

                  setFormValues({ ...formValues, details: '', address: newAddress.address, latitude, longitude });
                  setLocation({ lat: latitude, lng: longitude });
                } else {
                  NotificationManager.warning('Adresă invalidă!');
                }
              } else {
                NotificationManager.warning('A apărut o eroare!');
              }
            });
          } else {
            // Client or custom address - it already has coordinates
            setFormValues({ ...formValues, details: '', ...newAddress });
            setLocation({ lat: parseFloat(newAddress.latitude), lng: parseFloat(newAddress.longitude) });
          }
        }}
        inputValue={inputValue}
        onInputChange={(e, newValue, reason) => {
          setLoading(true);

          inputChangeReason.current = reason;
          setInputValue(newValue);

          if (value && reason === 'input') {
            setFormValues({ ...formValues, address: newValue, latitude: '', longitude: '', details: '' });
            setLocation(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            label="Adresa"
            variant="outlined"
            margin="normal"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={({ id, address, is_client, is_custom, details }) => {
          let cssClass = classes.googleAddress;
          let Icon = RoomIcon;

          if (is_client) {
            cssClass = classes.clientAddress;
            Icon = PersonIcon;
          }

          if (is_custom) {
            cssClass = classes.customAddress;
            Icon = StarIcon;
          }

          return (
            <Grid container alignItems="center" className={cssClass}>
              <Grid item className={classes.iconContainer}>
                <Icon className={classes.icon} />
              </Grid>

              <Grid item xs>
                <div>{address}</div>

                {details && <div>({details})</div>}
              </Grid>

              {is_client && (
                <Grid item>
                  <IconButton
                    size="small"
                    className={classes.clientAddress}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAddress(id);
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        }}
      />
    );
  }
);

AddressAutocomplete.propTypes = {
  clientAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  ),
  setFormValues: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  clientId: PropTypes.string
};

export default AddressAutocomplete;
