// React
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Google Maps
import { GoogleMap, Marker } from '@react-google-maps/api';

// Utils
import { NotificationManager } from 'react-notifications';

// Constants
import { DEFAULT_MAP_CENTER } from '../../../constants';

const mapContainerStyle = { width: '100%', height: '100%' };

const Map = ({ pinLocation, handleLocationChange, changedFromList }) => {
  const [mapInstance, setMapInstance] = useState(null);

  const handleMapLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  useEffect(() => {
    if (changedFromList && pinLocation) {
      mapInstance.setCenter(pinLocation);
      mapInstance.setZoom(17);
    }
  }, [mapInstance, pinLocation, changedFromList]);

  const mapOptions = useMemo(
    () => ({
      backgroundColor: 'transparent',
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      draggableCursor: 'pointer',
      draggingCursor: 'pointer'
    }),
    []
  );

  return (
    <GoogleMap
      zoom={14}
      clickableIcons={false}
      onLoad={handleMapLoad}
      mapContainerStyle={mapContainerStyle}
      center={DEFAULT_MAP_CENTER}
      options={mapOptions}
      onDblClick={({ latLng }) => {
        var geocoder = new window.google.maps.Geocoder();
        const location = { lat: latLng.lat(), lng: latLng.lng() };

        geocoder.geocode({ location }, function (results, status) {
          if (status === 'OK') {
            if (results[0]) {
              const { formatted_address } = results[0];

              handleLocationChange({
                address: formatted_address,
                longitude: location.lng,
                latitude: location.lat
              });
            } else {
              NotificationManager.warning('Adresă invalidă!');
            }
          } else {
            NotificationManager.warning('A apărut o eroare!');
          }
        });
      }}
    >
      {pinLocation && <Marker position={pinLocation} />}
    </GoogleMap>
  );
};

Map.propTypes = {
  changedFromList: PropTypes.bool.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  pinLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  })
};

export default Map;
