/* App reducer */

// Constants
import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL, CHANGE_THEME } from './constants';
import { DEFAULT_THEME } from '../../constants';

export const initialState = {
  loggingIn: false,
  loggingOut: false,
  authenticated: false,
  permissions: [],
  email: null,
  username: null,
  interior: null,
  theme: localStorage.getItem('theme') || DEFAULT_THEME
};

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, loggingIn: true };
    case LOGIN_SUCCESS:
      return {
        loggingIn: false,
        authenticated: true,
        permissions: action.permissions,
        username: action.username,
        email: action.email,
        interior: action.interior,
        theme: state.theme
      };
    case LOGIN_FAIL:
      return { ...state, loggingIn: false };
    case LOGOUT:
      return { ...state, loggingOut: true };
    case LOGOUT_SUCCESS:
      return { ...initialState };
    case LOGOUT_FAIL:
      return { ...state, loggingOut: false };
    case CHANGE_THEME:
      return { ...state, theme: action.theme };
    default:
      break;
  }
}
