/* PieChart view */

// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

// Material UI
import { useTheme } from '@material-ui/core/styles';

// Constants
import { ACCEPTED, CANCELED, PENDING } from '../../Routes/constants';

const PieChart = ({ data }) => {
  const { statusPalette, palette, typography } = useTheme();

  const options = useMemo(
    () => ({
      chart: {
        width: '100%'
      },
      labels: ['Comenzi complete', 'Comenzi anulate', 'Comenzi pierdute'],
      colors: [statusPalette[ACCEPTED], statusPalette[CANCELED], statusPalette[PENDING]],
      dataLabels: {
        style: {
          fontSize: 15,
          fontFamily: typography.fontFamily
        },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                color: palette.text.primary,
                fontFamily: typography.fontFamily
              },
              value: {
                color: palette.text.primary,
                fontSize: 18,
                fontFamily: typography.fontFamily
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: 18,
                fontFamily: typography.fontFamily,
                color: palette.text.primary
              }
            }
          }
        }
      }
    }),
    [typography, palette, statusPalette]
  );

  return (
    <Chart
      options={options}
      series={[data.total_completed, data.total_canceled, data.total_lost]}
      type="donut"
      width="100%"
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.shape({
    total_completed: PropTypes.number.isRequired,
    total_canceled: PropTypes.number.isRequired,
    total_lost: PropTypes.number.isRequired
  }).isRequired
};

export default PieChart;
