// Custom status cell content

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';

// Constants
import { REASON_MESSAGES } from '../../../constants';
import { STATUSES, CANCELED } from '../../Routes/constants';

const StatusCell = ({ status, reason }) => {
  if (status === CANCELED) {
    const reasonMsg = REASON_MESSAGES[reason] || reason;
    const statusMsg = status === CANCELED ? reasonMsg : STATUSES[status];

    return (
      <Box display="flex" alignItems="center">
        {statusMsg}
      </Box>
    );
  }

  return STATUSES[status];
};

StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  reason: PropTypes.string
};

export default StatusCell;
