/** Custon styled link */

// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',

    '&:hover': {
      color: 'inherit'
    },
    '&:active': {
      color: 'inherit'
    },
    '&:visited': {
      color: 'inherit'
    }
  }
});

const CustomMenuItem = ({ to, children }) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      {children}
    </Link>
  );
};

CustomMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default CustomMenuItem;
