// Active orders list

// React
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

// Components
import OriginIcon from '../Orders/OriginIcon';

// Utils
import clsx from 'clsx';
import { SocketContext } from '../../../context';
import { emitInfoByRadius } from '../../../utils/formatters';

// Constants
import { PENDING, ACCEPTED, PENDING_DIRECT_ALLOCATION } from '../../Routes/constants';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    maxHeight: '100%'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    maxWidth: 300,
    wordBreak: 'break-all'
  },
  [PENDING]: {
    color: theme.statusPalette[PENDING],
    fontWeight: 'bold'
  },
  [ACCEPTED]: {
    color: theme.statusPalette[ACCEPTED],
    fontWeight: 'bold'
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  pendingDirectAllocation: {
    width: 10,
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.statusPalette[PENDING],
    paddingTop: 0,
    paddingBottom: 0
  },
  phoneCell: {
    textAlign: 'center',
    width: 120
  }
}));

const ActiveOrders = () => {
  const classes = useStyles();

  const [orders, setOrders] = useState([]);

  const { socket, connected } = useContext(SocketContext);

  const updateOrders = useCallback(({ pending_orders }) => setOrders(pending_orders), []);

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.orders.overview.update', updateOrders);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.overview.update', updateOrders);
      }
    };
  }, [socket, connected, updateOrders]);

  const columns = useMemo(
    () => [
      {
        col: 'from_mobile_app',
        label: 'Origine',
        cls: classes.smallCell,
        format: (isMobile) => <OriginIcon isMobile={isMobile} />
      },
      { col: 'line', label: 'Linie', cls: classes.smallCell },
      { col: 'name', label: 'Client', align: 'center' },
      { col: 'phone', label: 'Telefon', cls: classes.phoneCell },
      { col: 'address', label: 'Adresa' },
      { col: 'details', label: 'Detalii' },
      { col: 'indicative', label: 'Mașina', cls: classes.smallCell },
      {
        col: 'nr_of_emits',
        label: 'Preluare',
        format: (emits, { status }) => (status === PENDING ? '-' : emitInfoByRadius(emits))
      }
    ],
    [classes]
  );

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map(({ col, label, cls, format, ...rest }) => (
              <TableCell key={col} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                {label}
              </TableCell>
            ))}

            <TableCell
              classes={{ root: clsx(classes.cell, classes.pendingDirectAllocation), head: classes.headCell }}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {orders.map((order) => {
            const isPendingDirectAllocation = order.status === PENDING_DIRECT_ALLOCATION;

            return (
              <TableRow key={order.id}>
                {columns.map(({ col, format, cls, ...rest }) => {
                  const value = order[col];

                  return (
                    <TableCell key={col} classes={{ root: clsx(classes.cell, classes[order.status], cls) }} {...rest}>
                      {format ? format(value, order) : value || '-'}
                    </TableCell>
                  );
                })}

                <Tooltip title={isPendingDirectAllocation ? 'În curs de alocare directă...' : ''}>
                  <TableCell className={classes.pendingDirectAllocation}>
                    {isPendingDirectAllocation ? '!' : null}
                  </TableCell>
                </Tooltip>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveOrders;
