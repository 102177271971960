// Change password form validation

import * as yup from 'yup';

// Constants
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../../constants';

export const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .max(PASSWORD_MAX_LENGTH, `Parola poate conține maxim ${PASSWORD_MAX_LENGTH} caractere`)
    .required('Câmp obligatoriu'),
  new_password: yup
    .string()
    .min(PASSWORD_MIN_LENGTH, `Parola trebuie să conțină minim ${PASSWORD_MIN_LENGTH} caractere`)
    .max(PASSWORD_MAX_LENGTH, `Parola poate conține maxim ${PASSWORD_MAX_LENGTH} caractere`)
    .matches(PASSWORD_REGEX, 'Parola trebuie să conțină minim 6 caractere, o literă mică și o literă mare')
    .required('Câmp obligatoriu'),
  new_password_re: yup.string().oneOf([yup.ref('new_password')], 'Parolele nu coincid')
});
