/* AuthTextfield - formik wrapped textfield with custom styling for auth layouts */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Textfield from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { useField } from 'formik';

const useStyles = makeStyles((theme) => {
  const borderColor = '#ffffff';
  const textColor = '#ffffff';

  return {
    textfield: {
      width: '100%'
    },
    root: {
      color: textColor,
      '& $notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.9)'
      },
      '&:hover $notchedOutline': {
        borderColor,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor
        }
      },
      '&$focused $notchedOutline': {
        borderColor
      }
    },
    notchedOutline: {},
    focused: {},
    label: {
      color: `${textColor} !important`
    },
    error: {
      color: theme.palette.error.main
    }
  };
});

function AuthTextfield({ label, name, ...rest }) {
  const classes = useStyles();

  const [field, meta] = useField(name);

  const error = meta.touched && !!meta.error;

  return (
    <React.Fragment>
      <Textfield
        {...field}
        label={label}
        variant="outlined"
        margin="normal"
        classes={{ root: classes.textfield }}
        error={error}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
            focused: classes.focused
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label
          }
        }}
        {...rest}
      />

      {error && <div className={classes.error}>{meta.error}</div>}
    </React.Fragment>
  );
}

AuthTextfield.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired
};

export default AuthTextfield;
