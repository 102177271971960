// React
import { useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { SocketContext } from '../../../context';
import findIndex from 'lodash/findIndex';

const DriversDataContainer = ({ children }) => {
  const [drivers, setDrivers] = useState([]);

  const { socket, connected } = useContext(SocketContext);

  // Trigger drivers get on load
  useEffect(() => {
    if (socket && connected) {
      socket.send({
        event: 'dispatchers.drivers.location.get'
      });
    }
  }, [socket, connected]);

  const updateDriver = useCallback(
    (userData) => {
      setDrivers((oldDrivers) => {
        const driverIndex = findIndex(oldDrivers, { id: userData.id });
        if (driverIndex > -1) {
          const newDrivers = [...oldDrivers];
          newDrivers[driverIndex] = userData;
          return newDrivers;
        } else {
          return [...oldDrivers, userData];
        }
      });
    },
    [setDrivers]
  );

  const loadDrivers = useCallback(
    ({ drivers: newDrivers }) => {
      setDrivers(newDrivers);
    },
    [setDrivers]
  );

  const deleteDrivers = useCallback(
    ({ ids }) => {
      setDrivers((oldDrivers) => oldDrivers.filter(({ id }) => !ids.includes(id)));
    },
    [setDrivers]
  );

  useEffect(() => {
    if (connected && socket) {
      socket.on('location.update', updateDriver);
      socket.on('dispatchers.drivers.location', loadDrivers);
      socket.on('dispatchers.drivers.delete', deleteDrivers);
    }

    return () => {
      if (connected && socket) {
        socket.off('location.update', updateDriver);
        socket.off('dispatchers.drivers.location', loadDrivers);
        socket.off('dispatchers.drivers.delete', deleteDrivers);
      }
    };
  }, [socket, connected, deleteDrivers, loadDrivers, updateDriver]);

  return children({ drivers });
};

DriversDataContainer.propTypes = {
  children: PropTypes.func.isRequired
};

export default DriversDataContainer;
