/* Loader, centered on first positioned parent */

// Raact
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

// Components
import CenteredXY from './CenteredXY';

const Loader = ({ size = 75, color, text }) => (
  <CenteredXY>
    {text ? (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <CircularProgress size={size} color={color} />

        <Box fontWeight="fontWeightBold" fontSize="1.2rem" mt={2}>
          {text}
        </Box>
      </Box>
    ) : (
      <CircularProgress size={size} color={color} />
    )}
  </CenteredXY>
);

Loader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  text: PropTypes.string
};

export default Loader;
