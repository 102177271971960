/* Admin dashboard */

// React
import React, { useContext, useEffect } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Components
import ActiveOrders from './ActiveOrders';
import InactiveOrders from './InactiveOrders';
import OverlayLoader from '../../Loader/OverlayLoader';

// Utils
import { AuthContext, SocketContext } from '../../../context';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  firstRow: {
    height: '50%'
  },
  secondRow: {
    height: '50%'
  }
});

const AdminDashboard = () => {
  const classes = useStyles();

  const { loggingOut } = useContext(AuthContext);

  const { retries, socket, connected } = useContext(SocketContext);

  // Trigger orders get on load
  useEffect(() => {
    if (socket && connected) {
      socket.send({
        event: 'dispatchers.orders.overview.get'
      });
    }
  }, [socket, connected]);

  return (
    <Box className={classes.root} position="relative">
      {!loggingOut && !connected && (
        <OverlayLoader text={`Se conectează...${retries > 1 ? `(reîncercarea ${retries})` : ''}`} />
      )}

      <Box className={classes.firstRow}>
        <ActiveOrders />
      </Box>

      <Box className={classes.secondRow}>
        <InactiveOrders />
      </Box>
    </Box>
  );
};

export default AdminDashboard;
