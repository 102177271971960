// React
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';

// Utils
import request, { useFetch } from '../../../utils/request';
import clsx from 'clsx';

// Constants
import { BACKEND_URL } from '../../../constants';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  }
}));

function ClientAddressesDialog({ onClose, clientId, clientName, clientPhone }) {
  const classes = useStyles();

  const [refresh, setRefresh] = useState(false);

  const { loading, data } = useFetch(`${BACKEND_URL}/customers/${clientId}/addresses`, null, refresh);

  const deleteAddress = useCallback(
    async (id) => {
      try {
        await request(`${BACKEND_URL}/customers/${clientId}/addresses/${id}/`, {
          method: 'DELETE'
        });

        setRefresh((oldValue) => !oldValue);

        NotificationManager.success(`Ștersă cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [clientId, setRefresh]
  );

  if (loading) {
    return <OverlayLoader fullScreen />;
  }
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
      <DialogTitle id="form-dialog-title">Adresele clientului {clientName || clientPhone}</DialogTitle>

      <DialogContent>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader classes={{ root: classes.table }}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.cell, head: classes.headCell }}>Adresa</TableCell>

                <TableCell classes={{ root: classes.cell, head: classes.headCell }}>Detalii</TableCell>

                <TableCell classes={{ root: classes.cell, head: classes.headCell }}>Ștergere</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(({ address, details, id }) => (
                <TableRow key={id}>
                  <TableCell classes={{ root: classes.cell }}>{address}</TableCell>

                  <TableCell classes={{ root: classes.cell }}>{details}</TableCell>

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <IconButton onClick={() => deleteAddress(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Închide
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ClientAddressesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  clientPhone: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired
};

export default ClientAddressesDialog;
