// Fetch
import 'whatwg-fetch';

// React
import { useEffect, useState } from 'react';

// Utils
import history from './history';
import { NotificationManager } from 'react-notifications';

// Constants
import { LOGIN_ROUTE, PUBLIC_ROUTES } from '../components/Routes/constants';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    localStorage.removeItem('token');

    // We shouldn't redirect the user if the route is a public one
    if (
      !PUBLIC_ROUTES.includes(window.location.pathname) &&
      !new RegExp('change-password').test(window.location.pathname)
    ) {
      setTimeout(() => {
        history.push({
          pathname: LOGIN_ROUTE
        });
      }, 150);
    }

    if (!response.url.includes('session')) {
      NotificationManager.error('Neautorizat!');
    }
    throw new Error('Neautorizat');
  }

  const error = new Error();
  error.response = response;

  let message = '';

  try {
    const responseObj = await response.json();
    message = responseObj.error;
  } catch (e) {
    message = 'A apărut o eroare';
  }

  error.message = message;

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    ...(!options.excludeAuthorization && token && { Authorization: `JWT ${token}` })
  };

  const config = {
    headers,
    ...options
  };

  return fetch(url, config).then(checkStatus).then(parseJSON);
}

export function useFetch(url, options = {}, refresh) {
  const [opts] = useState(options);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setLoading(true);
    setError(null);

    (async () => {
      try {
        const response = await request(url, {
          ...opts,
          signal
        });

        setData(response);
      } catch (error) {
        setError(error.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [url, opts, refresh]);

  return { loading, data, error };
}
