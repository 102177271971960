/** Modal used to choose a reason for order cancellation */

// React
import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

// Constants
import { REASON_MESSAGES, CANCELED_DISPATCHER, WRONG_ORDER } from '../../../constants';

// Utils
import { SocketContext } from '../../../context';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 0
  },
  title: {
    background: theme.table.headerBackground,
    textAlign: 'center'
  },
  content: {
    padding: 0
  },
  listItem: {
    borderBottom: `1px solid ${theme.table.headerBackground}`,
    textAlign: 'center'
  },
  input: {
    paddingTop: 8,
    paddingBottom: 8
  }
}));

const reasons = [CANCELED_DISPATCHER, WRONG_ORDER];

function CancelOrderDialog({ orderId, onClose }) {
  const classes = useStyles();

  const { socket, connected } = useContext(SocketContext);

  const cancelOrderError = useCallback(
    ({ error, ID }) => {
      if (orderId === ID) {
        NotificationManager.error(error);

        onClose();
      }
    },
    [orderId, onClose]
  );

  const cancelOrderSuccess = useCallback(
    ({ ID }) => {
      if (orderId === ID) {
        onClose();
      }
    },
    [orderId, onClose]
  );

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.orders.cancel.success', cancelOrderSuccess);
      socket.on('dispatchers.orders.cancel.error', cancelOrderError);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.cancel.success', cancelOrderSuccess);
        socket.off('dispatchers.orders.cancel.error', cancelOrderError);
      }
    };
  }, [socket, connected, cancelOrderError, cancelOrderSuccess]);

  const handleOrderCancel = useCallback(
    (code, message) => {
      if (socket && connected) {
        socket.send({
          event: 'dispatchers.orders.cancel',
          id: orderId,
          reason: code,
          reason_details: message
        });
      }
    },
    [orderId, socket, connected]
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      aria-labelledby="order-cancel-reasons"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="order-cancel-reasons" classes={{ root: classes.title }}>
        Motivul anulării
      </DialogTitle>

      <DialogContent classes={{ root: classes.content }}>
        <List disablePadding>
          {reasons.map((code) => (
            <ListItem key={code} button onClick={() => handleOrderCancel(code)} classes={{ root: classes.listItem }}>
              <ListItemText>{REASON_MESSAGES[code]}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

CancelOrderDialog.propTypes = {
  orderId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CancelOrderDialog;
