// React
import React, { useContext, useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ClearIcon from '@material-ui/icons/Clear';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import { makeStyles, darken } from '@material-ui/core/styles';

// Google Maps
import { GoogleMap, OverlayView, Marker } from '@react-google-maps/api';
import { getBounds } from 'geolib';

// Components
import PenalizeDialog from './PenalizeDialog';
import ConfirmationModal from '../../ConfirmationDialog';

// Utils
import { LocationContext } from '../../Providers/LocationProvider';
import clsx from 'clsx';
import moment from 'moment';
import { SocketContext } from '../../../context';

// Constants
import { DEFAULT_MAP_CENTER } from '../../../constants';
import { MAP_ROUTE } from '../../Routes/constants';

const useStyles = makeStyles((theme) => {
  const { activeDriver, penalizedDriver } = theme.statusPalette;

  return {
    '@keyframes pulse-danger': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 82, 82, 0.7)'
      },

      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(255, 82, 82, 0)'
      },

      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 82, 82, 0)'
      }
    },
    centerBtn: {
      position: 'absolute',
      bottom: 10,
      left: 10,
      padding: 2,
      color: 'rgba(0, 0, 0, 0.54)',

      '& svg': {
        width: '2.5rem',
        height: '2.5rem'
      }
    },
    fullscreenBtn: {
      position: 'absolute',
      top: 10,
      left: 10,
      padding: 2,
      color: 'rgba(0, 0, 0, 0.54)',

      '& svg': {
        width: '2.5rem',
        height: '2.5rem'
      }
    },
    carIndicative: {
      position: 'relative',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: 16,
      color: '#ffffff',
      textShadow: 'none',
      background: activeDriver,
      transition: 'all 350ms',
      borderRadius: '3px',
      padding: '3px 6px',
      lineHeight: 1,

      '&:hover': {
        background: darken(activeDriver, 0.2)
      },

      '&$penalizedCarIndicative': {
        background: penalizedDriver,

        '&:hover': {
          background: darken(penalizedDriver, 0.2)
        }
      },

      '&$carInDanger': {
        background: penalizedDriver,
        boxShadow: `0 0 0 0 ${penalizedDriver}`,
        animation: '$pulse-danger 1s infinite'
      }
    },
    penalizedCarIndicative: {},
    carInDanger: {},
    filterContainer: {
      position: 'absolute',
      right: 10,
      top: 10
    },
    input: {
      paddingTop: 4,
      paddingBottom: 4
    },
    inputRoot: {
      color: 'rgba(0, 0, 0, 0.8)',

      '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.54)'
      },

      '&:hover $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.87)'
      },

      '&$focused $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.87)'
      }
    },
    notchedOutline: {},
    focused: {},
    clearIcon: {
      cursor: 'pointer',
      position: 'absolute',
      color: 'rgba(0, 0, 0, 0.54)',
      right: 10,
      top: 5,
      fontSize: '1.2rem'
    },
    markerLabel: {
      background: '#EA4335',
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 50
    }
  };
});

const mapContainerStyle = { width: '100%', height: '100%' };

function Map({ drivers, fullScreen, filterFromParent, setFilterFromParent, activeOrders = [] }) {
  const classes = useStyles();

  const { socket, connected } = useContext(SocketContext);

  const [activeDriverProps, setActiveDriverProps] = useState(null);

  const { pathname } = useLocation();
  const isMapRoute = pathname === MAP_ROUTE;

  const [filter, setFilter] = useState('');

  const [freeMap, setFreeMap] = useState(false);

  const [mapInstance, setMapInstance] = useState(null);

  const { location, setLocation } = useContext(LocationContext);

  useEffect(() => {
    if (filterFromParent && filterFromParent !== filter) {
      setFilter(filterFromParent);
    }
  }, [filterFromParent, filter]);

  const filteredDrivers = useMemo(
    () => drivers.filter(({ indicative }) => indicative.toLowerCase().includes(filter.toLowerCase())),
    [drivers, filter]
  );

  const mapOptions = useMemo(
    () => ({
      backgroundColor: 'transparent',
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      draggableCursor: 'pointer',
      draggingCursor: 'pointer'
    }),
    []
  );

  const handleStopPanic = useCallback(
    ({ id }) => {
      if (socket && connected) {
        socket.send({
          event: 'dispatchers.driver.panic.stop',
          ID: id
        });
      }
    },
    [socket, connected]
  );

  const handleFilterChange = useCallback(
    ({ target }) => {
      setFilterFromParent?.(target.value);
      setFilter(target.value);
      setFreeMap(false);
    },
    [setFilterFromParent]
  );

  useEffect(() => {
    if (!freeMap && mapInstance) {
      if (pathname !== MAP_ROUTE && location) {
        mapInstance.setCenter(location);
        mapInstance.setZoom(17);
        return;
      }

      if (filteredDrivers.length || activeOrders.length) {
        const { minLat, minLng, maxLat, maxLng } = getBounds([
          ...activeOrders.map(({ latitude, longitude }) => ({
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude)
          })),
          ...filteredDrivers.map(({ latitude, longitude }) => ({ latitude, longitude }))
        ]);

        mapInstance.fitBounds(
          new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(minLat, minLng),
            new window.google.maps.LatLng(maxLat, maxLng)
          )
        );
      }
    }
  }, [mapInstance, filteredDrivers, freeMap, location, pathname, activeOrders]);

  const handleMapLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  return (
    <GoogleMap
      zoom={14}
      clickableIcons={false}
      mapContainerStyle={mapContainerStyle}
      onLoad={handleMapLoad}
      onDragStart={() => setFreeMap(true)}
      center={DEFAULT_MAP_CENTER}
      options={mapOptions}
      onDblClick={({ latLng }) => {
        if (!isMapRoute) {
          setLocation({ lat: latLng.lat(), lng: latLng.lng() }, true);
        }
      }}
    >
      {!isMapRoute && location && <Marker position={location} />}

      {activeOrders.map(({ id, latitude, longitude, address }) => (
        <Marker
          key={id}
          position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
          label={{ text: address, color: '#ffffff', className: classes.markerLabel }}
        />
      ))}

      {filteredDrivers.map(
        ({
          id,
          latitude,
          longitude,
          indicative,
          username,
          is_penalized,
          is_panic,
          penalization_starts_at,
          penalization_ends_at
        }) => (
          <OverlayView
            key={id}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
          >
            <div
              className={clsx(
                classes.carIndicative,
                is_penalized && classes.penalizedCarIndicative,
                is_panic && classes.carInDanger
              )}
              onClick={() => {
                // Now or in the future
                const penalized =
                  penalization_starts_at && penalization_ends_at && moment().isBefore(moment(penalization_ends_at));

                setActiveDriverProps({
                  id,
                  indicative,
                  username,
                  penalized,
                  is_panic,
                  end: penalization_ends_at,
                  start: penalization_starts_at
                });
              }}
            >
              {indicative}
            </div>
          </OverlayView>
        )
      )}

      <div className={classes.filterContainer}>
        <TextField
          margin="none"
          variant="outlined"
          placeholder="Filtreză după indicativ"
          value={filter}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
              focused: classes.focused
            }
          }}
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              /* clear the search on Esc key */
              setFilter('');
            }
          }}
        />

        {filter && (
          <ClearIcon
            className={classes.clearIcon}
            onClick={() => {
              setFilter('');
              setFilterFromParent?.('');
            }}
          />
        )}
      </div>

      {!fullScreen && (
        <Tooltip title="Mărește harta">
          <IconButton classes={{ root: classes.fullscreenBtn }} component="a" target="_blank" href={MAP_ROUTE}>
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
      )}

      {freeMap && (
        <Tooltip title="Recentrează">
          <IconButton classes={{ root: classes.centerBtn }} onClick={() => setFreeMap(false)}>
            <CenterFocusStrongIcon />
          </IconButton>
        </Tooltip>
      )}

      {activeDriverProps &&
        (activeDriverProps.is_panic ? (
          <ConfirmationModal
            onClose={() => setActiveDriverProps(null)}
            confirmCallback={() => handleStopPanic(activeDriverProps)}
            message={`Oprești alerta pentru șoferul ${activeDriverProps.indicative}?`}
          />
        ) : (
          <PenalizeDialog {...activeDriverProps} onClose={() => setActiveDriverProps(null)} />
        ))}
    </GoogleMap>
  );
}

Map.propTypes = {
  filterFromParent: PropTypes.string,
  setFilterFromParent: PropTypes.func,
  fullScreen: PropTypes.bool,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      indicative: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      is_penalized: PropTypes.bool.isRequired,
      is_panic: PropTypes.bool.isRequired,
      penalization_starts_at: PropTypes.string.isRequired,
      penalization_ends_at: PropTypes.string.isRequired,
      nr_of_orders_in_shift: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  activeOrders: PropTypes.array
};

export default Map;
