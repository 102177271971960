/* Forgot password form validation */

import * as yup from 'yup';

// Constants
import { USERNAME_MAX_LENGTH } from '../../../constants';

export const validationSchema = yup.object().shape({
  username: yup
    .string()
    .max(USERNAME_MAX_LENGTH, `Numele de utilizator poate conține maxim ${USERNAME_MAX_LENGTH} caractere`)
    .required('Câmp obligatoriu')
});
