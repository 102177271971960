/* Auth logo & form error container */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../../images/logo.png';

// Utils
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '70%',
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    paddingBottom: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main,

    '& $logo': {
      fill: theme.palette.error.main
    }
  }
}));

const AuthLogo = ({ error }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img alt="Logo" src={Logo} className={classes.logo} />
      </Box>

      <div className={clsx(classes.errorContainer, error && classes.error)}>{error}</div>
    </>
  );
};

AuthLogo.propTypes = {
  error: PropTypes.string
};

export default AuthLogo;
