/* Textfield - formik wrapped textfield */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Textfield from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { useField } from 'formik';

const useStyles = makeStyles(() => ({
  textfield: {
    width: '100%'
  }
}));

function CustomTextField({ label, name, classes, ...rest }) {
  const textClasses = useStyles();

  const [field, { error, touched }] = useField(name);

  return (
    <Textfield
      {...field}
      label={label}
      margin="normal"
      classes={{ root: textClasses.textfield, ...classes }}
      error={touched && !!error}
      helperText={touched && error}
      {...rest}
    />
  );
}

CustomTextField.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.object
};

export default CustomTextField;
