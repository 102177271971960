/* Switch - formik wrapped switch */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';

// Formik
import { useField } from 'formik';

function Switch({ label, name, formControlLabelProps, ...rest }) {
  const [field] = useField(name);

  return (
    <FormControlLabel
      {...formControlLabelProps}
      control={<MuiSwitch {...field} checked={field.value} {...rest} />}
      label={label}
    />
  );
}

Switch.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  formControlLabelProps: PropTypes.object
};

export default Switch;
