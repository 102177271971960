/* Absolute positioned container used to center content */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    transform: 'translate(-50%, -50%)'
  }
});

const CenteredXY = ({ children, maxWidth = 'none', fullWidth }) => {
  const classes = useStyles();

  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      className={classes.root}
      maxWidth={maxWidth}
      width={fullWidth ? '100%' : 'auto'}
    >
      {children}
    </Box>
  );
};

CenteredXY.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool
};

export default CenteredXY;
