/** Simple menu item structure */

// React
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Material UI
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Link from '../../Link';

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === 'dark';
  const hoverColor = isDarkTheme ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.1)';
  const selectedColor = isDarkTheme ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.15)';

  return {
    icon: {
      minWidth: 48,
      color: 'inherit'
    },
    listItem: {
      '&:hover': {
        backgroundColor: hoverColor
      }
    },
    selected: {
      '&&': {
        backgroundColor: selectedColor,

        '&:hover': {
          backgroundColor: selectedColor
        }
      }
    }
  };
});

const CustomMenuItem = ({ text, Icon, route }) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <Link to={route}>
      <ListItem
        component="div"
        button
        selected={route === pathname}
        classes={{ root: classes.listItem, selected: classes.selected }}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <Icon />
        </ListItemIcon>

        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{text}</ListItemText>
      </ListItem>
    </Link>
  );
};

CustomMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
  route: PropTypes.string.isRequired
};

export default CustomMenuItem;
