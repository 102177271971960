/* AuthBackground - simple container with basic styles for auth layouts */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const firstColor = `hsl(${Math.random() * 360}, 75%, 20%)`;
const secondColor = `hsl(${Math.random() * 360}, 75%, 20%)`;
const useStyles = makeStyles({
  root: {
    backgroundImage: `linear-gradient(20deg, ${firstColor} , ${secondColor})`
  }
});

function AuthBackground({ children }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      width="100%"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {children}
    </Box>
  );
}

AuthBackground.propTypes = {
  children: PropTypes.any
};

export default AuthBackground;
