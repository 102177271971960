/* Utility function used to download a file */

import assignIn from 'lodash/assignIn';

const FILENAME_DOWNLOAD_NAME = 'filename=';

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error();
  error.response = response;

  let message = '';

  try {
    const responseObj = await response.json();
    message = responseObj.error;
  } catch (e) {
    message = 'A apărut o eroare';
  }

  error.message = message;

  throw error;
}

const requestHandlerDownload = (url, callback, opts) => {
  const token = localStorage.getItem('token');

  const config = assignIn(opts || {}, {
    mode: 'cors',
    headers: {
      Authorization: `JWT ${token}`
    }
  });

  return fetch(url, config)
    .then(checkStatus)
    .then((r) => convertToBlob(r))
    .then((blob) => interpretJsonResponseDownload(blob, callback))
    .catch((err) => {
      console.error(err);
      callback(err);
    });
};

const mime = 'application/octet-stream';
let filename = '';

const convertToBlob = (response, callback) => {
  const contentDisposition = response.headers.get('content-disposition');

  if (contentDisposition) {
    filename = contentDisposition.substring(
      contentDisposition.indexOf(FILENAME_DOWNLOAD_NAME) + FILENAME_DOWNLOAD_NAME.length,
      contentDisposition.length
    );
  }

  return response.blob();
};

const interpretJsonResponseDownload = (blob, callback) => {
  /**
   * This method should interpret only the download type requests
   */
  var newBlob = new Blob([blob], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(newBlob, filename);
  } else {
    var blobURL = window.URL.createObjectURL(newBlob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  callback();
};

export default requestHandlerDownload;
