// Permissions
export const DISPATCHER_PERMISSION = 'login.dispatcher';
export const DRIVER_PERMISSION = 'login.driver';
export const READ_USERS_PERMISSION = 'read.users';
export const MANAGE_USERS_PERMISSION = 'manage.users';
export const READ_STATISTICS_PERMISSION = 'read.statistics';
export const MANAGE_CONFIG_PERMISSION = 'manage.config';
export const ORDERS_OVERVIEW_PERMISSION = 'orders.overview';

export const allPermissions = [
  {
    value: DISPATCHER_PERMISSION,
    label: 'Dispecer'
  },
  {
    value: DRIVER_PERMISSION,
    label: 'Șofer'
  },
  {
    value: ORDERS_OVERVIEW_PERMISSION,
    label: 'Vizualizare globală comenzi'
  },
  {
    value: READ_USERS_PERMISSION,
    label: 'Vizualizare utilizatori'
  },
  {
    value: MANAGE_USERS_PERMISSION,
    label: 'Modificare utilizatori'
  },
  {
    value: READ_STATISTICS_PERMISSION,
    label: 'Vizualizare statistici'
  },
  {
    value: MANAGE_CONFIG_PERMISSION,
    label: 'Modificare setări aplicație'
  }
];

// Checks if "userPermissions" contain at least one of "permissions"
export const checkPermissions = (userPermissions = [], permissions = []) => {
  let hasPerm = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < permissions.length; i++) {
    if (userPermissions.indexOf(permissions[i]) > -1) {
      hasPerm = true;
      break;
    }
  }

  return hasPerm;
};
