/* Calls list */

// React
import React, { useContext, useEffect, useState, useCallback } from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

// Components
import ConfirmationModal from '../../ConfirmationDialog';

// Utils
import clsx from 'clsx';
import { SocketContext } from '../../../context';
import findIndex from 'lodash/findIndex';
import request from '../../../utils/request';
import { NotificationManager } from 'react-notifications';

// Constants
import { ANSWERED_STATUS, BACKEND_URL, HOLDED_STATUS, NEW_STATUS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    maxHeight: '100%'
  },
  deleteIcon: {
    fontSize: '1.2rem'
  },
  cell: {
    padding: theme.table.cellPadding
  },
  smallCell: {
    width: 40,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  [ANSWERED_STATUS]: {
    color: theme.statusPalette[ANSWERED_STATUS],
    fontWeight: 'bold'
  },
  [HOLDED_STATUS]: {
    color: theme.statusPalette[HOLDED_STATUS],
    fontWeight: 'bold'
  },
  [NEW_STATUS]: {
    color: theme.statusPalette[NEW_STATUS],
    fontWeight: 'bold'
  },
  detailsCell: {
    wordBreak: 'break-word'
  }
}));

const CallsList = () => {
  const classes = useStyles();

  const [calls, setCalls] = useState([]);

  const { socket, connected } = useContext(SocketContext);

  const [confirmDeleteProps, setConfirmDeleteProps] = useState(null);

  const loadCalls = useCallback(
    ({ calls }) => {
      setCalls(
        calls.map(({ customer: { id, phone, name, description }, line, status, orders, total_orders }) => ({
          id,
          name,
          phone,
          description,
          line,
          orders,
          status,
          total_orders
        }))
      );
    },
    [setCalls]
  );

  const addCall = useCallback(
    ({ customer: { id, phone, name }, line, total_orders }) => {
      const newCall = {
        id,
        phone,
        line,
        total_orders,
        status: NEW_STATUS,
        name
      };

      setCalls((callsArray) => {
        const index = findIndex(callsArray, { id });
        const newArray = [...callsArray];

        if (index > -1) {
          newArray.splice(index, 1, newCall);
        } else {
          newArray.push(newCall);
        }

        return newArray;
      });
    },
    [setCalls]
  );

  const removeCall = useCallback(
    ({ customer: { id } }) => {
      setCalls((callsArray) => callsArray.filter((call) => call.id !== id));
    },
    [setCalls]
  );

  const updateCall = useCallback(
    ({ id, line, phone, status, total_orders, name, orders, description }) => {
      setCalls((callsArray) => {
        const index = findIndex(callsArray, { id });
        const newArray = [...callsArray];

        if (index > -1) {
          newArray[index] = {
            ...newArray[index],
            line,
            status,
            total_orders,
            name,
            orders,
            description
          };
        } else {
          newArray.push({ id, line, phone, status, total_orders, name, orders, description });
        }

        return newArray;
      });
    },
    [setCalls]
  );

  const answerCall = useCallback(
    ({ customer: { id, phone, name, description }, line, orders, total_orders }) => {
      updateCall({ id, line, phone, description, status: ANSWERED_STATUS, orders, total_orders, name });
    },
    [updateCall]
  );

  const holdCall = useCallback(
    ({ customer: { id, phone, name, description }, line, orders, total_orders }) => {
      updateCall({ id, line, phone, description, status: HOLDED_STATUS, orders, total_orders, name });
    },
    [updateCall]
  );

  const unholdCall = useCallback(
    ({ customer: { id, phone, name, description }, line, orders, total_orders }) => {
      updateCall({ id, line, phone, description, status: ANSWERED_STATUS, orders, total_orders, name });
    },
    [updateCall]
  );

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.customers.calls.active', loadCalls);
      socket.on('dispatchers.phone.call.new', addCall);
      socket.on('dispatchers.phone.call.ended', removeCall);
      socket.on('dispatchers.phone.call.answered', answerCall);
      socket.on('dispatchers.phone.call.holded', holdCall);
      socket.on('dispatchers.phone.call.answered_from_hold', unholdCall);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.customers.calls.active', loadCalls);
        socket.off('dispatchers.phone.call.new', addCall);
        socket.off('dispatchers.phone.call.ended', removeCall);
        socket.off('dispatchers.phone.call.answered', answerCall);
        socket.off('dispatchers.phone.call.holded', holdCall);
        socket.off('dispatchers.phone.call.answered_from_hold', unholdCall);
      }
    };
  }, [socket, connected, loadCalls, addCall, removeCall, answerCall, holdCall, unholdCall]);

  const deleteCall = useCallback(
    async (id) => {
      try {
        await request(`${BACKEND_URL}/customers/${id}/call/end`, {
          method: 'POST'
        });

        // Remove the call
        setCalls((callsArray) => callsArray.filter((call) => call.id !== id));

        setConfirmDeleteProps(null);
        NotificationManager.success(`Șters cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmDeleteProps, setCalls]
  );

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      {confirmDeleteProps && <ConfirmationModal onClose={() => setConfirmDeleteProps(null)} {...confirmDeleteProps} />}

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
              Linie
            </TableCell>

            <TableCell classes={{ root: classes.cell, head: classes.headCell }}>Detalii</TableCell>

            <TableCell classes={{ root: classes.cell, head: classes.headCell }} />
          </TableRow>
        </TableHead>

        <TableBody>
          {calls.map((call) => {
            const { id, status, phone, name, line, total_orders } = call;

            return (
              <TableRow key={id}>
                <TableCell classes={{ root: clsx(classes.cell, classes[status], classes.smallCell) }}>{line}</TableCell>

                <TableCell
                  onDoubleClick={() => {
                    if (status === ANSWERED_STATUS || status === HOLDED_STATUS) {
                      socket.emit('duplicate.call', call);
                    }
                  }}
                  classes={{ root: clsx(classes.cell, classes[status], classes.detailsCell) }}
                >
                  {name || 'Fără nume'} ({total_orders})
                  <br />
                  {phone}
                </TableCell>

                <TableCell classes={{ root: classes.cell }}>
                  <Tooltip title="Șterge">
                    <IconButton
                      size="small"
                      className={classes[status]}
                      onClick={() =>
                        setConfirmDeleteProps({
                          confirmCallback: () => deleteCall(id),
                          message: `Ștergi apelul de pe linia ${line} - ${phone} ?`
                        })
                      }
                    >
                      <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CallsList;
