export const secondsToMinutes = (seconds) =>
  seconds ? `${parseInt(seconds / 60, 10)}m ${parseInt(seconds % 60, 10)}s` : '-';

export const emitInfoByRadius = (nr_of_emits) => {
  if (!nr_of_emits) {
    return '-';
  }

  let info;

  switch (nr_of_emits) {
    case 1:
      info = 'Prima strigare';
      break;
    case 2:
      info = 'A doua strigare';
      break;
    case 3:
      info = 'Salvare comandă';
      break;
    default:
      info = 'Obligată';
      break;
  }

  return info;
};
