// React
import React, { useEffect, useCallback, useContext } from 'react';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Utils
import { useSnackbar } from 'notistack';
import { SocketContext } from '../../../context';

const OrderNotTakenNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { socket, connected } = useContext(SocketContext);

  const saveNotifications = useCallback(
    ({ message }) =>
      enqueueSnackbar(message, {
        persist: true,
        variant: 'warning',
        action: (key) => (
          <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )
      }),
    [enqueueSnackbar, closeSnackbar]
  );

  useEffect(() => {
    if (socket && connected) {
      socket.on('dispatchers.notification.order.not_taken', saveNotifications);
    }

    return () => {
      if (socket && connected) {
        socket.off('dispatchers.notification.order.not_taken', saveNotifications);
      }
    };
  }, [socket, connected, saveNotifications]);

  return null;
};

export default OrderNotTakenNotifications;
