/* Login form */

// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

// Formik
import { Form, Formik } from 'formik';

// Material UI
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

// Components
import AuthTextfield from './AuthTextfield';
import AuthBackground from './AuthBackground';
import AuthButton from './AuthButton';
import AuthLogo from './AuthLogo';
import OverlayLoader from '../../Loader/OverlayLoader';
import Link from '../../Link';

// Utils
import { validationSchema } from './validation';
import request from '../../../utils/request';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

// Actions
import { login, loginFail, loginSuccess } from '../../App/actions';

// Constants
import { FORGOT_PASSWORD_ROUTE } from '../../Routes/constants';
import { BACKEND_URL } from '../../../constants';

const useStyles = makeStyles({
  firstTextfield: {
    marginTop: 0
  },
  selectArrow: {
    color: '#fff'
  }
});

const Login = ({ dispatch }) => {
  const classes = useStyles();

  const [interiors, setInteriors] = useState([]);

  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await request(`${BACKEND_URL}/interiors`, { excludeAuthorization: true });
        setInteriors(response);
      } catch (ex) {
        console.log('Interiors get failed...');
      }
    })();
  }, []);

  const handleSubmit = async (values) => {
    dispatch(login());

    try {
      const jwtToken = await request(`${BACKEND_URL}/login`, {
        method: 'POST',
        body: JSON.stringify(pickBy(values, identity)),
        excludeAuthorization: true
      });

      localStorage.setItem('token', jwtToken);

      const data = jwtDecode(jwtToken);
      dispatch(loginSuccess(data));
    } catch (error) {
      dispatch(loginFail());

      setError(error.message);
    }
  };

  return (
    <AuthBackground>
      <Box maxWidth={400} width="100%" p={2}>
        <Formik
          initialValues={{
            username: '',
            password: '',
            interior: ''
          }}
          initialErrors={{
            dummyError: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              {isSubmitting && <OverlayLoader />}

              <AuthLogo error={error} />

              <AuthTextfield autoFocus required label="Utilizator" name="username" className={classes.firstTextfield} />

              <AuthTextfield required label="Parola" name="password" type="password" />

              <AuthTextfield
                label="Interior"
                name="interior"
                select
                SelectProps={{
                  classes: { icon: classes.selectArrow },
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    }
                  }
                }}
              >
                {interiors.map(({ id, description }) => (
                  <MenuItem key={id} value={id}>
                    {id} - {description}
                  </MenuItem>
                ))}
              </AuthTextfield>

              <AuthButton text="Autentificare" disabled={!isValid || isSubmitting} />

              <Box color="#ffffff" textAlign="center" pt={3}>
                <Link to={FORGOT_PASSWORD_ROUTE}>Ai uitat parola?</Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthBackground>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default Login;
