// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import moment from 'moment';
import { secondsToMinutes } from '../../../utils/formatters';

const Timer = ({ startDate }) => {
  const [seconds, setSeconds] = useState(startDate ? moment().diff(startDate, 'seconds') : 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((old) => old + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return secondsToMinutes(seconds);
};

Timer.propTypes = {
  startDate: PropTypes.string
};

export default Timer;
