// React
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: theme.typography.fontFamily
  },
  content: {
    wordBreak: 'break-word'
  }
}));

function ConfirmationModal({
  message,
  title = 'Confirmare',
  confirmCallback,
  cancelCallback,
  onClose,
  showCancelButton = true,
  showConfirmButton = true,
  confirmButtonText = 'Da',
  cancelButtonText = 'Nu'
}) {
  const classes = useStyles();

  const handleSubmit = useCallback(() => {
    if (typeof confirmCallback === 'function') {
      confirmCallback();
    }
    onClose();
  }, [onClose, confirmCallback]);

  const handleClose = useCallback(() => {
    if (typeof cancelCallback === 'function') {
      cancelCallback();
    }
    onClose();
  }, [onClose, cancelCallback]);

  return (
    <Dialog
      open
      fullWidth
      onClose={handleClose}
      aria-labelledby="confirmation-title"
      maxWidth="xs"
      classes={{ root: classes.dialog }}
    >
      <DialogTitle id="confirmation-title">{title}</DialogTitle>

      <DialogContent classes={{ root: classes.content }}>{message}</DialogContent>

      <DialogActions>
        {showCancelButton && <Button onClick={handleClose}>{cancelButtonText}</Button>}

        {showConfirmButton && (
          <Button onClick={handleSubmit} color="primary">
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
  confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  title: PropTypes.node,
  showCancelButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
};

export default ConfirmationModal;
