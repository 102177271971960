// React
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import request, { useFetch } from '../../../utils/request';
import clsx from 'clsx';
import { NotificationManager } from 'react-notifications';

// Constants
import { BACKEND_URL } from '../../../constants';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';
import SearchField from '../../Form/SearchField';
import TablePaginationActions from '../../TablePaginationActions';
import ConfirmationModal from '../../ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1)
  },
  tableContainer: {
    width: '100%',
    maxHeight: 'calc(100vh - 280px)'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.smCellPadding,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: theme.table.smCellPadding
  },
  coordinatesCell: {
    width: 110
  },
  paginationToolbar: {
    overflow: 'hidden',
    minHeight: 52
  }
}));

const List = ({ handleLocationChange, resetForm, refresh, setRefresh, editedAddressId }) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('rowsPerPage') || 10);
  const [search, setSearch] = useState('');

  const [confirmDeleteProps, setConfirmDeleteProps] = useState(null);

  const { loading, data } = useFetch(
    `${BACKEND_URL}/addresses/custom?page=${page}&limit=${rowsPerPage}&q_address=${search}`,
    null,
    refresh
  );

  const columns = useMemo(
    () => [
      { id: 'address', label: 'Adresa' },
      { id: 'latitude', label: 'Latitudine', cls: classes.coordinatesCell },
      { id: 'longitude', label: 'Longitudine', cls: classes.coordinatesCell }
    ],
    [classes]
  );

  const deleteAddress = useCallback(
    async (id) => {
      try {
        await request(`${BACKEND_URL}/address/${id}/custom`, {
          method: 'DELETE'
        });

        if (editedAddressId === id) {
          resetForm();
        }

        // Reset page if there are no results and the page is not the first one
        if (data.data.length === 1 && page > 1) {
          setPage(1);
        } else {
          setRefresh((oldValue) => !oldValue);
        }

        setConfirmDeleteProps(null);
        NotificationManager.success(`Ștearsă cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmDeleteProps, setRefresh, data, page, setPage, editedAddressId, resetForm]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage + 1);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      localStorage.setItem('rowsPerPage', +event.target.value);
      setRowsPerPage(+event.target.value);
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );

  return (
    <Paper classes={{ root: classes.paper }}>
      {loading && <OverlayLoader />}

      {confirmDeleteProps && <ConfirmationModal onClose={() => setConfirmDeleteProps(null)} {...confirmDeleteProps} />}

      <Box display="flex" p={0.5} alignItems="center" justifyContent="space-between">
        <SearchField
          className={classes.search}
          handleSearch={(val) => {
            setSearch(val);
            setPage(1);
          }}
        />
      </Box>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, cls, label, format, children, ...rest }) => (
                <TableCell key={id} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {children || label}
                </TableCell>
              ))}

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Editare
              </TableCell>

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Ștergere
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.data.map((adr) => {
                const { id, latitude, longitude, address } = adr;
                return (
                  <TableRow key={id}>
                    {columns.map(({ id, cls }) => {
                      const value = adr[id];

                      return (
                        <TableCell key={id} classes={{ root: clsx(classes.cell, cls) }}>
                          {value || '-'}
                        </TableCell>
                      );
                    })}

                    <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleLocationChange(
                            { id, address, latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
                            true
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setConfirmDeleteProps({
                            confirmCallback: () => deleteAddress(id),
                            message: `Ștergi adresa ${address} ?`
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`}
        classes={{ root: classes.paginationToolbar }}
        component="div"
        count={data ? data.total : 0}
        rowsPerPage={rowsPerPage}
        page={data ? page - 1 : 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

List.propTypes = {
  handleLocationChange: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  editedAddressId: PropTypes.string
};

export default List;
