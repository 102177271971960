// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { Formik, Form } from 'formik';

// Utils
import request from '../../../utils/request';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

// Constants
import { BACKEND_URL, DATE_FORMAT } from '../../../constants';

// Components
import DatetimeRangePicker from '../../DateTimePicker';

const useStyles = makeStyles({
  overflowVisible: {
    overflow: 'visible'
  }
});

function PenalizeDialog({ onClose, id, indicative, username, penalized, start, end, saveCallback }) {
  const classes = useStyles();
  const endDate = moment(end);
  const startDate = moment(start);

  const initialValues = useMemo(() => {
    const currentDate = moment();
    const hasPenalty = endDate.isAfter(currentDate);

    return {
      penalization_starts_at: hasPenalty ? startDate : currentDate,
      penalization_ends_at: hasPenalty ? endDate : currentDate.clone().add(1, 'hours')
    };
  }, [endDate, startDate]);

  const addPenalty = async ({ penalization_starts_at, penalization_ends_at }, { setErrors, setSubmitting }) => {
    try {
      await request(`${BACKEND_URL}/drivers/${id}/penalize`, {
        method: 'POST',
        body: JSON.stringify({
          penalization_starts_at: penalization_starts_at.toISOString(),
          penalization_ends_at: penalization_ends_at.toISOString()
        })
      });
      NotificationManager.success(`Șoferul a fost penalizat cu succes!`);

      if (saveCallback && typeof saveCallback === 'function') {
        saveCallback();
      }

      setSubmitting(false);
      onClose();
    } catch (e) {
      NotificationManager.error(`A apărut o eroare`);
      setErrors({ penalization_starts_at: e.message });
      setSubmitting(false);
    }
  };

  const removePenalty = async () => {
    try {
      await request(`${BACKEND_URL}/drivers/${id}/penalize`, {
        method: 'DELETE'
      });
      NotificationManager.success(`Penalizarea șoferului ${indicative} a fost ștearsă cu succes!`);

      if (saveCallback && typeof saveCallback === 'function') {
        saveCallback();
      }

      onClose();
    } catch (e) {
      NotificationManager.error(`A apărut o eroare ${e.message}`);
    }
  };

  return (
    <Dialog open classes={{ paper: classes.overflowVisible }} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Penalizare șofer {indicative} - {username}
      </DialogTitle>

      <Formik initialValues={initialValues} onSubmit={addPenalty}>
        {({ isSubmitting, values, setFieldValue, setFieldTouched }) => (
          <Form>
            <DialogContent classes={{ root: classes.overflowVisible }}>
              <DatetimeRangePicker
                timeFormat="HH:mm"
                dateFormat={DATE_FORMAT}
                defaultStartDate={startDate}
                startDate={values.penalization_starts_at}
                defaultEndDate={endDate}
                endDate={values.penalization_ends_at}
                startInputProps={{ placeholder: 'De la...' }}
                endInputProps={{ placeholder: 'până la...' }}
                onChange={({ start, end }) => {
                  setFieldValue('penalization_starts_at', start);
                  setFieldValue('penalization_ends_at', end);
                  setFieldTouched('penalization_starts_at', true);
                  setFieldTouched('penalization_ends_at', true);
                }}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Renunță
              </Button>

              {penalized && (
                <Button onClick={removePenalty} color="primary" disabled={isSubmitting || !start || !end}>
                  Șterge
                </Button>
              )}

              <Button type="submit" color="primary" disabled={isSubmitting}>
                Salvează
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

PenalizeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  indicative: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  penalized: PropTypes.bool.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  saveCallback: PropTypes.func
};

export default PenalizeDialog;
