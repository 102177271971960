import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h5>{this.state.error.message}</h5>;
    }
    return this.props.children;
  }
}
