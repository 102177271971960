// React
import React, { useState, useEffect, useContext, useCallback } from 'react';

// Material-ui
import Box from '@material-ui/core/Box';

// Components
import Map from './Map';
import DriversDataContainer from './DriversDataContainer';
import DriversList from './DriversList';

// Utils
import { SocketContext } from '../../../context';

// Constants
import { ACCEPTED } from '../../Routes/constants';

const FullscreenMap = () => {
  const [filterFromParent, setFilterFromParent] = useState('');

  const [activeOrders, setActiveOrders] = useState([]);

  const { socket, connected } = useContext(SocketContext);

  const updateOrders = useCallback(
    ({ pending_orders }) => setActiveOrders(pending_orders.filter(({ status }) => status === ACCEPTED)),
    [setActiveOrders]
  );

  useEffect(() => {
    if (connected && socket) {
      socket.send({
        event: 'dispatchers.orders.get'
      });

      socket.on('dispatchers.orders.update', updateOrders);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.update', updateOrders);
      }
    };
  }, [socket, connected, updateOrders]);

  return (
    <DriversDataContainer>
      {({ drivers }) => (
        <Box display="flex" height="100%">
          <Box width={400} mr={1}>
            <DriversList
              drivers={drivers}
              filterFromParent={filterFromParent}
              setFilterFromParent={setFilterFromParent}
            />
          </Box>

          <Box flex={1}>
            <Map
              activeOrders={activeOrders}
              drivers={drivers}
              fullScreen
              filterFromParent={filterFromParent}
              setFilterFromParent={setFilterFromParent}
            />
          </Box>
        </Box>
      )}
    </DriversDataContainer>
  );
};

export default FullscreenMap;
