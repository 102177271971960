// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Formik
import { Formik, FieldArray } from 'formik';

// Utils
import request from '../../../utils/request';
import { validationSchema } from './validation';
import { NotificationManager } from 'react-notifications';

// Constants
import { BACKEND_URL } from '../../../constants';
import { allPermissions, DRIVER_PERMISSION } from '../../../utils/permissions';

// Components
import CustomTextField from '../../Form/CustomTextField';
import Switch from '../../Form/Switch';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    display: 'block',
    '&:first-child': {
      marginTop: theme.spacing(3)
    }
  },
  permissionsContainer: {
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  error: {
    color: theme.palette.error.main
  },
  switch: {
    marginTop: theme.spacing(2)
  }
}));

const fields = [
  { label: 'Utilizator', required: true, name: 'username' },
  { label: 'Adresă email', required: true, name: 'email', type: 'email' },
  { label: 'Nume', name: 'name' },
  { label: 'Prenume', name: 'forename' },
  { label: 'Telefon', name: 'car_phone_number', required: true, onlyForDriver: true },
  { label: 'Indicativ', name: 'indicative', required: true, onlyForDriver: true },
  { label: 'Număr înmatriculare', name: 'car_plate_number', required: true, onlyForDriver: true },
  { label: 'Marcă mașină', name: 'car_brand', required: true, onlyForDriver: true },
  { label: 'Culoare mașină', name: 'car_color', required: true, onlyForDriver: true }
];
const switches = [
  { label: 'Bagaje voluminoase', name: 'car_big_luggage', onlyForDriver: true },
  { label: 'Fumători', name: 'car_smoker', onlyForDriver: true },
  { label: 'Animale de companie', name: 'car_animals', onlyForDriver: true }
];

const initialState = {
  ...fields.reduce((acc, { name }) => {
    acc[name] = '';
    return acc;
  }, {}),
  ...switches.reduce((acc, { name }) => {
    acc[name] = false;
    return acc;
  }, {}),
  permissions: [],
  is_active: true
};

export default function UserFormDialog({ userId, isDialogOpen, setIsDialogOpen, refresh, setRefresh }) {
  const isEdit = userId !== '-1';
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(isEdit);
  const [hasError, setHasError] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  useEffect(() => {
    if (isEdit) {
      (async () => {
        try {
          const response = await request(`${BACKEND_URL}/users/${userId}`);
          setData(response);
        } catch (ex) {
          NotificationManager.error(`A apărut o eroare ${ex.message}`);
          setHasError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [userId, isEdit, setHasError, setLoading]);

  if (hasError || loading) {
    return null;
  }

  const saveUser = async (values) => {
    try {
      await request(`${BACKEND_URL}/users${isEdit ? `/${userId}` : ''}`, {
        method: isEdit ? 'PUT' : 'POST',
        body: JSON.stringify(values)
      });

      NotificationManager.success('Utilizator salvat cu success!');

      setIsDialogOpen(false);
      setRefresh(!refresh);
    } catch (ex) {
      NotificationManager.error(`A apărut o eroare ${ex.message}`);
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <Formik enableReinitialize validationSchema={validationSchema} initialValues={data} onSubmit={saveUser}>
        {({ handleSubmit, values: { permissions, is_active }, isValid, dirty, errors }) => {
          const isDriver = permissions.includes(DRIVER_PERMISSION);
          return (
            <>
              <DialogTitle id="form-dialog-title">{isEdit ? 'Editare utilizator' : 'Utilizator nou'}</DialogTitle>

              <DialogContent>
                <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
                  <Box flex={2} pr={3}>
                    <Grid container spacing={3}>
                      {fields.map(
                        ({ Component = CustomTextField, onlyForDriver, ...fieldProps }, index) =>
                          (!onlyForDriver || isDriver) && (
                            <Grid key={index} item xs={12} sm={6}>
                              <Component autoFocus={index === 0} {...fieldProps} />
                            </Grid>
                          )
                      )}
                    </Grid>

                    <Box
                      display="flex"
                      pt={3.5}
                      pb={2}
                      justifyContent="space-between"
                      flexDirection={{ xs: 'column', lg: 'row' }}
                    >
                      {switches.map(
                        ({ onlyForDriver, ...fieldProps }, index) =>
                          (!onlyForDriver || isDriver) && (
                            <Box key={index}>
                              <Switch {...fieldProps} />
                            </Box>
                          )
                      )}
                    </Box>
                  </Box>

                  <Box flex={1} pt={2} className={classes.permissionsContainer}>
                    <strong>Permisiuni</strong>

                    <FieldArray name="permissions">
                      {(arrayHelpers) =>
                        allPermissions.map(({ value, label }) => (
                          <FormControlLabel
                            classes={{ root: classes.formControlLabel }}
                            key={value}
                            label={label}
                            control={
                              <Checkbox
                                color="primary"
                                checked={permissions.indexOf(value) !== -1}
                                value={value}
                                onChange={({ target: { checked } }) => {
                                  if (checked) {
                                    arrayHelpers.push(value);
                                  } else {
                                    arrayHelpers.remove(permissions.indexOf(value));
                                  }
                                }}
                              />
                            }
                          />
                        ))
                      }
                    </FieldArray>

                    {errors.permissions && <div className={classes.error}>{errors.permissions}</div>}

                    <Switch
                      name="is_active"
                      label={is_active ? 'Activat' : 'Dezactivat'}
                      color="primary"
                      formControlLabelProps={{ classes: { root: classes.switch } }}
                    />
                  </Box>
                </Box>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                  Renunță
                </Button>

                <Button onClick={handleSubmit} color="primary" disabled={!dirty || !isValid}>
                  Salvează
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}

UserFormDialog.propTypes = {
  userId: PropTypes.string,
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};
