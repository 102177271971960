/** Modal used to view order details */

// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import moment from 'moment';
import clsx from 'clsx';

// Constants
import { DATE_TIME_FORMAT, REASON_MESSAGES } from '../../../constants';
import { CANCELED, STATUSES } from '../../Routes/constants';

const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
    background: theme.table.headerBackground,
    textAlign: 'center'
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  infoTable: {
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  tableContainer: {
    width: '100%'
  },
  cell: {
    padding: '4px 8px',
    maxWidth: 300,
    wordBreak: 'break-all'
  },
  smallCell: {
    width: 60,
    textAlign: 'center'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    whiteSpace: 'nowrap'
  }
}));

const calculateEmitGroup = (emits) => {
  if (!emits) {
    return 'Nu s-a emis';
  }

  if (emits < 3) {
    return `Perimetrul ${emits}`;
  }

  if (emits === 3) {
    return 'Toți șoferii';
  }

  return 'Obligata';
};

function OrderDetailsDialog({
  onClose,
  created_at,
  address,
  nr_of_emits,
  b_nr_of_emits,
  status,
  reason,
  indicative,
  drivers
}) {
  const classes = useStyles();

  const reasonMsg = REASON_MESSAGES[reason] || reason;
  const statusMsg = status === CANCELED ? reasonMsg : STATUSES[status];

  const driversColumns = useMemo(
    () => [
      { col: 'indicative', label: 'Indicativ', cls: classes.smallCell },
      { col: 'logged_in', label: 'Conectat', cls: classes.smallCell, format: (v) => (v ? 'Da' : 'Nu') },
      { col: 'wasPenalized', label: 'Penalizat', cls: classes.smallCell, format: (v) => (v ? 'Da' : 'Nu') },
      { col: 'distance_to_order', label: 'Distanță', align: 'center', format: (v) => (v ? `${v}m` : '-') },
      { col: 'nr_of_orders_in_shift', label: 'Număr comenzi în schimb', align: 'center' },
      { col: 'priority_level', label: 'Prioritate', cls: classes.smallCell },
      {
        col: 'last_order_taken',
        label: 'Ultima comandă',
        format: (v) => (v ? moment(v).format(DATE_TIME_FORMAT) : '-')
      },
      {
        col: 'last_location_updated_at',
        label: 'Locație actualizată la',
        format: (v) => (v ? moment(v).format('DD/MM/YYYY HH:mm:ss') : '-')
      }
    ],
    [classes]
  );

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose} aria-labelledby="order-details">
      <DialogTitle id="order-details" classes={{ root: classes.title }}>
        Detalii comandă
        <IconButton onClick={onClose} classes={{ root: classes.closeBtn }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box justifyContent="center" alignItems="center">
          <div>
            <table className={classes.infoTable}>
              <tbody>
                <tr>
                  <td>Data:</td>
                  <td>
                    <b>{moment(created_at).format('DD/MM/YYYY HH:mm:ss')}</b>
                  </td>
                </tr>

                <tr>
                  <td>Adresa:</td>
                  <td>
                    <b>{address}</b>
                  </td>
                </tr>

                <tr>
                  <td>Status:</td>
                  <td>
                    <b>{statusMsg}</b>
                  </td>
                </tr>

                <tr>
                  <td>Emisă pentru:</td>
                  <td>
                    <b>{calculateEmitGroup(nr_of_emits)}</b>
                  </td>
                </tr>

                <tr>
                  <td>Perimetru:</td>
                  <td>
                    <b>{nr_of_emits || 'Neexistent'}</b>
                  </td>
                </tr>

                <tr>
                  <td>Perimetru precedent:</td>
                  <td>
                    <b>{b_nr_of_emits || 'Neexistent'}</b>
                  </td>
                </tr>

                {drivers?.length && indicative ? (
                  <tr>
                    <td>Preluată de:</td>
                    <td>
                      <b>{indicative}</b>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </Box>

        <br />

        {drivers?.length ? (
          <>
            {' '}
            <b>Șoferii care s-au anunțat:</b>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {driversColumns.map(({ col, format, label, cls, ...rest }) => (
                      <TableCell
                        key={col}
                        classes={{ root: clsx(classes.cell, cls), head: classes.headCell }}
                        {...rest}
                      >
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {drivers.map((driver) => (
                    <TableRow key={driver.id}>
                      {driversColumns.map(({ col, format, cls, label, ...rest }) => {
                        const value = driver[col];

                        return (
                          <TableCell key={col} classes={{ root: clsx(classes.cell, cls) }} {...rest}>
                            {format ? format(value) : value ?? '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

OrderDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  created_at: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  nr_of_emits: PropTypes.number.isRequired,
  b_nr_of_emits: PropTypes.number,
  status: PropTypes.string.isRequired,
  reason: PropTypes.string,
  indicative: PropTypes.string,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logged_in: PropTypes.bool.isRequired,
      indicative: PropTypes.string.isRequired,
      last_location_updated_at: PropTypes.string.isRequired,
      last_order_taken: PropTypes.string.isRequired,
      priority_level: PropTypes.number,
      wasPenalized: PropTypes.bool.isRequired,
      distance_to_order: PropTypes.string.isRequired,
      nr_of_orders_in_shift: PropTypes.number.isRequired
    })
  ).isRequired
};

export default OrderDetailsDialog;
