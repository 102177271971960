/* LineChart view */

// React
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

// Material UI
import { useTheme, makeStyles } from '@material-ui/core/styles';

// Constants
import { ACCEPTED, CANCELED, PENDING } from '../../Routes/constants';

const useStyles = makeStyles({
  chart: {
    '& svg': {
      // Fox for cropped labels
      overflow: 'visible'
    }
  }
});

const LineChart = ({ data }) => {
  const classes = useStyles();

  const { statusPalette, palette } = useTheme();

  const categories = [];
  const completed = [];
  const canceled = [];
  const lost = [];
  let max = 0;
  let min = 0;

  data.forEach(({ total_completed, total_canceled, total_lost, date }) => {
    max = Math.max(max, total_completed, total_canceled, total_lost);
    min = Math.min(min, total_completed, total_canceled, total_lost);

    categories.push(date);
    completed.push(total_completed);
    canceled.push(total_canceled);
    lost.push(total_lost);
  });

  const options = useMemo(
    () => ({
      colors: [statusPalette[ACCEPTED], statusPalette[CANCELED], statusPalette[PENDING]],
      chart: {
        zoom: false,
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories,
        labels: {
          style: {
            colors: palette.text.primary
          }
        }
      },
      yaxis: {
        show: false,
        min,
        max
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      tooltip: {
        theme: palette.type
      }
    }),
    [statusPalette, palette.type, palette.text.primary, min, max]
  );

  return (
    <Chart
      className={classes.chart}
      type="line"
      height={450}
      options={options}
      series={[
        { name: 'Comenzi complete', data: completed },
        { name: 'Comenzi anulate', data: canceled },
        { name: 'Comenzi pierdute', data: lost }
      ]}
    />
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
      total: PropTypes.number.isRequired,
      total_completed: PropTypes.number.isRequired,
      total_canceled: PropTypes.number.isRequired,
      total_lost: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default LineChart;
