// Custom origin icon content (mobile vs dispatcher)

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

const OriginIcon = ({ isMobile }) => (
  <Box display="flex" alignItems="center" justifyContent="center">
    {isMobile ? <PhoneAndroidIcon /> : <ContactPhoneIcon />}
  </Box>
);

OriginIcon.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default OriginIcon;
