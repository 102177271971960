// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { Formik } from 'formik';

// Utils
import request from '../../../utils/request';
import { validationSchema } from './validation';
import { NotificationManager } from 'react-notifications';

// Constants
import { BACKEND_URL } from '../../../constants';

// Components
import CustomTextField from '../../Form/CustomTextField';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main
  }
}));

function ChangePasswordDialog({ closeDialog }) {
  const [error, setError] = useState(null);

  const classes = useStyles();

  const updatePassword = async (values) => {
    try {
      await request(`${BACKEND_URL}/user/change-password`, {
        method: 'PUT',
        body: JSON.stringify(values)
      });
      NotificationManager.success(`Parola a fost schimbată cu succes!`);

      closeDialog();
    } catch (e) {
      NotificationManager.error(`A apărut o eroare ${e.message}`);
      setError(e.message);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={closeDialog} aria-labelledby="form-dialog-title">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ old_password: '', new_password: '', new_password_re: '' }}
        onSubmit={updatePassword}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <>
            <DialogTitle id="form-dialog-title">Schimbare parolă</DialogTitle>

            <DialogContent>
              {error && <div className={classes.error}>{error}</div>}
              <CustomTextField autoFocus label="Parola curentă" required name="old_password" type="password" />
              <CustomTextField label="Noua parolă" required name="new_password" type="password" />
              <CustomTextField label="Confirmare parolă" required name="new_password_re" type="password" />
            </DialogContent>

            <DialogActions>
              <Button onClick={closeDialog} color="secondary">
                Renunță
              </Button>

              <Button onClick={handleSubmit} color="primary" disabled={!dirty || !isValid}>
                Salvează
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

ChangePasswordDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired
};

export default ChangePasswordDialog;
