// React
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

// Formik
import { Formik, Form } from 'formik';

// Utils
import { SocketContext } from '../../../context';
import { NotificationManager } from 'react-notifications';
import range from 'lodash/range';
import * as yup from 'yup';

// Components
import CustomTextField from '../../Form/CustomTextField';

const validationSchema = yup.object().shape({
  indicatives: yup.array().of(yup.string().required('Câmp obligatoriu'))
});

function IndicativeEditDialog({ onClose, orderId, nrOfCars }) {
  const { socket, connected } = useContext(SocketContext);

  const initialValues = useMemo(
    () => ({
      indicatives: range(0, nrOfCars).map(() => '')
    }),
    [nrOfCars]
  );

  const addIndicative = useCallback(
    (formValues, formActions) => {
      socket.send({
        event: 'dispatchers.orders.driver.add',
        order_id: orderId,
        ...formValues
      });
      formActions.setSubmitting(false);
    },
    [socket, orderId]
  );

  const driverAddSuccess = useCallback(() => {
    NotificationManager.success('Salvat cu succes!');
    onClose();
  }, [onClose]);

  const driverAddError = useCallback(({ error_message }) => {
    NotificationManager.error(error_message);
  }, []);

  useEffect(() => {
    if (connected && socket) {
      socket.on('dispatchers.orders.driver.add.success', driverAddSuccess);
      socket.on('dispatchers.orders.driver.add.error', driverAddError);
    }

    return () => {
      if (connected && socket) {
        socket.off('dispatchers.orders.driver.add.success', driverAddSuccess);
        socket.off('dispatchers.orders.driver.add.error', driverAddError);
      }
    };
  }, [socket, connected, driverAddSuccess, driverAddError]);

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">Modificare indicativ</DialogTitle>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={addIndicative}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogContent>
              {range(0, nrOfCars).map((index) => (
                <CustomTextField
                  autoFocus={index === 0}
                  label={nrOfCars === 1 ? 'Indicativ' : `Indicativ ${index + 1}`}
                  key={index}
                  name={`indicatives[${index}]`}
                />
              ))}
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Renunță
              </Button>

              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                Salvează
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

IndicativeEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  nrOfCars: PropTypes.number.isRequired
};

export default IndicativeEditDialog;
