/* Custom table pagination actions */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles();

  const { count, page, rowsPerPage, onChangePage } = props;

  const createClickHandler = (newPage) => (event) => {
    onChangePage(event, newPage);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={createClickHandler(0)} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={createClickHandler(page - 1)} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton onClick={createClickHandler(page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={createClickHandler(Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default TablePaginationActions;
