import * as yup from 'yup';
import { DRIVER_PERMISSION } from '../../../utils/permissions';

const driverRequiredFieldCheck = (permissions, schema) =>
  permissions && permissions.includes(DRIVER_PERMISSION) ? schema.required('Câmp obligatoriu') : schema;

export const validationSchema = yup.object().shape({
  username: yup.string().required('Câmp obligatoriu').trim().max(255, 'Lungime maximă 255 caractere'),
  name: yup.string().max(255, 'Lungime maximă 255 caractere'),
  forename: yup.string().max(255, 'Lungime maximă 255 caractere'),
  email: yup.string().required('Câmp obligatoriu').email('Email invalid').max(100, 'Lungime maximă 100 caractere'),
  permissions: yup.array().min(1, 'Cel puțin o permisiune trebuie selectată'),
  indicative: yup.string().max(20, 'Lungime maximă 20 caractere').when('permissions', driverRequiredFieldCheck),
  car_phone_number: yup.string().max(20, 'Lungime maximă 20 caractere').when('permissions', driverRequiredFieldCheck),
  car_plate_number: yup.string().max(20, 'Lungime maximă 20 caractere').when('permissions', driverRequiredFieldCheck),
  car_brand: yup.string().max(20, 'Lungime maximă 20 caractere').when('permissions', driverRequiredFieldCheck),
  car_color: yup.string().max(20, 'Lungime maximă 20 caractere').when('permissions', driverRequiredFieldCheck),
  car_big_luggage: yup.bool().when('permissions', driverRequiredFieldCheck),
  car_smoker: yup.bool().when('permissions', driverRequiredFieldCheck),
  car_animals: yup.bool().when('permissions', driverRequiredFieldCheck)
});
