/* Forgot password form */

// React
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Formik
import { Form, Formik } from 'formik';

// Material UI
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Components
import AuthTextfield from '../Login/AuthTextfield';
import AuthBackground from '../Login/AuthBackground';
import AuthButton from '../Login/AuthButton';
import AuthLogo from '../Login/AuthLogo';
import OverlayLoader from '../../Loader/OverlayLoader';
import Link from '../../Link';

// Utils
import { validationSchema } from './validation';
import { NotificationManager } from 'react-notifications';
import request from '../../../utils/request';

// Constants
import { LOGIN_ROUTE } from '../../Routes/constants';
import { BACKEND_URL } from '../../../constants';

const useStyles = makeStyles({
  firstTextfield: {
    marginTop: 0
  }
});

const ForgotPassword = () => {
  const classes = useStyles();

  const [error, setError] = useState(null);

  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      await request(`${BACKEND_URL}/user/forgot-password`, {
        method: 'POST',
        body: JSON.stringify(values),
        excludeAuthorization: true
      });

      history.push(LOGIN_ROUTE);
      NotificationManager.success('Un email de confirmare a fost trimis. Vă rugăm urmați instrucțiunile din acesta.');
    } catch (error) {
      NotificationManager.warning('A apărut o eroare.');
      setError(error.message);
    }
  };

  return (
    <AuthBackground>
      <Box maxWidth={400} width="100%" p={2}>
        <Formik
          initialValues={{
            username: ''
          }}
          initialErrors={{
            dummyError: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              {isSubmitting && <OverlayLoader />}

              <AuthLogo error={error} />

              <AuthTextfield autoFocus required label="Utilizator" name="username" className={classes.firstTextfield} />

              <AuthButton text="Resetează parola" disabled={!isValid || isSubmitting} />

              <Box color="#ffffff" textAlign="center" pt={3}>
                <Link to={LOGIN_ROUTE}>Înapoi la login</Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthBackground>
  );
};

export default ForgotPassword;
