/** Tabpanel for material-ui tabs */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

function TabPanel({ children, value, index }) {
  const classes = useStyles();

  return (
    <Typography component="div" role="tabpanel" classes={{ root: classes.root }} hidden={value !== index}>
      {children}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default TabPanel;
