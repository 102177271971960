/* Filter for orders history */

// React
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import clsx from 'clsx';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import getQueryString from '../../../utils/getQueryString';
import requestDownload from '../../../utils/download';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';
import DatetimeRangePicker from '../../DateTimePicker';

// Constants
import { COMPLETED, CANCELED, STATUSES } from '../../Routes/constants';
import { BACKEND_URL, DATE_FORMAT } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  field: {
    margin: theme.spacing(0.5)
  },
  smallInput: {
    width: 85
  },
  mediumInput: {
    width: 150
  },
  labelMarginDense: {},
  shrink: {},
  outlined: {
    fontSize: '0.9rem',

    '&$labelMarginDense': {
      transform: 'translate(14px, 9px) scale(1)'
    },

    '&$shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    }
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6
  },
  selectInput: {
    minWidth: 100
  }
}));

const Filters = ({ applyFilters, setPage }) => {
  const classes = useStyles();

  const initialState = {
    q_line: '',
    q_interior: '',
    q_indicative: '',
    q_address: '',
    q_details: '',
    q_name: '',
    q_phone: '',
    q_created_at_start: moment().startOf('day'),
    q_created_at_end: moment().toISOString(),
    q_status: '',
    q_from_mobile_app: ''
  };

  const [filters, setFilters] = useState(initialState);

  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const [downloading, setDownloading] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  const handleReportClick = () => {
    const params = {
      ...pickBy(
        {
          ...filters,
          q_created_at_start: moment(filters.q_created_at_start).toISOString(),
          q_created_at_end: moment(filters.q_created_at_end).toISOString()
        },
        identity
      )
    };

    if (isBoolean(filters.q_from_mobile_app)) {
      params.q_from_mobile_app = filters.q_from_mobile_app;
    }

    setDownloading(true);

    requestDownload(`${BACKEND_URL}/orders/download?${getQueryString(params)}`, (e) => {
      if (e) {
        NotificationManager.error(e.message);
      }

      setDownloading(false);
    });
  };

  const handleResetBtnClick = () => {
    setPage(1);

    setHasActiveFilters(false);
    setFilters(initialState);

    setIsDirty(false);

    applyFilters({
      // Same as initial state from OrdersHistory
      q_created_at_start: moment().startOf('day').toISOString(),
      q_created_at_end: moment().toISOString()
    });
  };

  const handleFilterBtnClick = () => {
    setPage(1);

    setIsDirty(false);

    setHasActiveFilters(true);
    applyFilters({
      ...filters,
      q_created_at_start: moment(filters.q_created_at_start).toISOString(),
      q_created_at_end: moment(filters.q_created_at_end).toISOString()
    });
  };

  const createChangeHandler = (filter) => ({ target }) => {
    setIsDirty(true);

    setFilters((oldFilters) => ({ ...oldFilters, [filter]: target.value }));
  };

  const createNumericChangeHandler = (filter, min) => ({ target }) => {
    const { value } = target;

    const isLess = isNumber(min) && value < min;

    setIsDirty(true);

    setFilters((oldFilters) => ({ ...oldFilters, [filter]: isLess ? min : value }));
  };

  const filtersCfg = useMemo(
    () => [
      {
        field: 'q_line',
        label: 'Linie',
        cls: classes.smallInput,
        type: 'number',
        inputProps: { min: 0 },
        onChange: createNumericChangeHandler('q_line', 0)
      },
      {
        field: 'q_interior',
        label: 'Consola',
        cls: classes.smallInput,
        type: 'number',
        inputProps: { min: 0 },
        onChange: createNumericChangeHandler('q_interior', 0)
      },
      { field: 'q_indicative', label: 'Mașina', cls: classes.smallInput },
      { field: 'q_address', label: 'Adresa' },
      { field: 'q_details', label: 'Detalii' },
      { field: 'q_name', label: 'Client', cls: classes.mediumInput },
      { field: 'q_phone', label: 'Telefon', cls: classes.mediumInput },
      {
        field: 'q_status',
        label: 'Status',
        select: true,
        cls: classes.selectInput,
        children: [COMPLETED, CANCELED].map((code) => (
          <MenuItem key={code} value={code}>
            {STATUSES[code]}
          </MenuItem>
        ))
      },
      {
        field: 'q_from_mobile_app',
        label: 'Origine',
        select: true,
        cls: classes.selectInput,
        children: [
          <MenuItem key="mobil" value={true}>
            Mobil
          </MenuItem>,

          <MenuItem key="dispecer" value={false}>
            Dispecer
          </MenuItem>
        ]
      }
    ],
    [classes]
  );

  return (
    <div className={classes.root}>
      {downloading && <OverlayLoader fullScreen text="Se generează jurnalul. Vă rugăm așteptați..." />}

      <Typography classes={{ root: classes.title }} gutterBottom>
        Filtrare avansată
      </Typography>

      <Box display="flex" flexWrap="wrap">
        {filtersCfg.map(({ field, label, cls, ...rest }) => (
          <TextField
            key={field}
            InputLabelProps={{
              classes: { marginDense: classes.labelMarginDense, outlined: classes.outlined, shrink: classes.shrink }
            }}
            InputProps={{ classes: { input: classes.input } }}
            classes={{ root: clsx(classes.field, cls) }}
            variant="outlined"
            size="small"
            onChange={createChangeHandler(field)}
            label={label}
            value={filters[field]}
            {...rest}
          />
        ))}

        <DatetimeRangePicker
          timeFormat="HH:mm"
          dateFormat={DATE_FORMAT}
          startDate={filters.q_created_at_start}
          endDate={filters.q_created_at_end}
          startInputProps={{ placeholder: 'De la...' }}
          endInputProps={{ placeholder: 'până la...' }}
          onChange={({ start, end }) => {
            setIsDirty(true);

            setFilters((oldFilters) => ({ ...oldFilters, q_created_at_start: start, q_created_at_end: end }));
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button classes={{ root: classes.button }} onClick={handleReportClick}>
          Generare jurnal trafic
        </Button>

        <Button
          classes={{ root: classes.button }}
          disabled={!isDirty && !hasActiveFilters}
          onClick={handleResetBtnClick}
        >
          Resetare
        </Button>

        <Button classes={{ root: classes.button }} disabled={!isDirty} color="primary" onClick={handleFilterBtnClick}>
          Filtrare
        </Button>
      </Box>
    </div>
  );
};

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

export default Filters;
