/* Main container */

// React
import React, { useReducer, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Switch } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

// Notifications
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// Constants
import {
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  PRIVACY_ROUTE,
  SUPPORT_ROUTE
} from '../Routes/constants';
import { BACKEND_URL } from '../../constants';

// Components
import Login from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import ChangePassword from '../Auth/ChangePassword';
import Core from '../Core';
import PrivateRoute from '../Routes/PrivateRoute';
import PublicRoute from '../Routes/PublicRoute';
import OverlayLoader from '../Loader/OverlayLoader';
import PrivacyPolicy from '../Auth/PrivacyPolicy';
import SupportPage from '../Auth/SupportPage';

// Utils
import { AuthContext } from '../../context';
import { useFetch } from '../../utils/request';

// State
import { reducer, initialState } from './reducer';

// Actions
import { loginSuccess, logoutSuccess } from './actions';

// Theme
import { ThemeProvider } from '@material-ui/core/styles';
import createTheme from '../../theme';

const googleLibraries = ['places'];

const App = () => {
  const [{ authenticated, loggingIn, loggingOut, permissions, username, interior, theme }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const [initialLoggingIn, setInitialLoggingIn] = useState(true);

  const { loading: sessionLoading, error } = useFetch(`${BACKEND_URL}/user/session`);

  useEffect(() => {
    if (!sessionLoading) {
      if (error) {
        dispatch(logoutSuccess());
        setInitialLoggingIn(false);
      } else {
        const jwtToken = localStorage.getItem('token');
        const data = jwtDecode(jwtToken);
        dispatch(loginSuccess(data));
        setInitialLoggingIn(false);
      }
    }
  }, [error, sessionLoading, dispatch, setInitialLoggingIn]);

  const currentTheme = useMemo(() => createTheme(theme), [theme]);

  if (sessionLoading || initialLoggingIn) {
    return <OverlayLoader fullScreen />;
  }

  const routeProps = {
    authenticated
  };

  return (
    <AuthContext.Provider value={{ permissions, username, interior, dispatch, theme, authenticated, loggingOut }}>
      {loggingIn && <OverlayLoader fullScreen />}

      <Switch>
        <PublicRoute {...routeProps} path={PRIVACY_ROUTE} Component={<PrivacyPolicy />} />

        <PublicRoute {...routeProps} path={SUPPORT_ROUTE} Component={<SupportPage />} />

        <PublicRoute {...routeProps} path={LOGIN_ROUTE} Component={<Login dispatch={dispatch} />} />

        <PublicRoute {...routeProps} path={FORGOT_PASSWORD_ROUTE} Component={<ForgotPassword />} />

        <PublicRoute {...routeProps} path={CHANGE_PASSWORD_ROUTE} Component={<ChangePassword />} />

        <PrivateRoute
          {...routeProps}
          Component={
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              libraries={googleLibraries}
              preventGoogleFontsLoading
              loadingElement={<OverlayLoader fullScreen />}
              version="3"
            >
              <ThemeProvider theme={currentTheme}>
                <Core />
              </ThemeProvider>
            </LoadScript>
          }
        />
      </Switch>

      <NotificationContainer />
    </AuthContext.Provider>
  );
};

export default App;
