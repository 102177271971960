// React
import React, { useState, useMemo, useCallback } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import request, { useFetch } from '../../../utils/request';
import clsx from 'clsx';
import { NotificationManager } from 'react-notifications';

// Constants
import { BACKEND_URL } from '../../../constants';

// Components
import OverlayLoader from '../../Loader/OverlayLoader';
import SearchField from '../../Form/SearchField';
import TablePaginationActions from '../../TablePaginationActions';
import ConfirmationModal from '../../ConfirmationDialog';
import ClientFormDialog from '../ClientFormDialog';
import ClientAddressesDialog from './ClientAddressesDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  tableContainer: {
    width: '100%'
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    wordBreak: 'break-all'
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  phoneCell: {
    width: 120
  },
  paginationToolbar: {
    overflow: 'hidden',
    minHeight: 52
  },
  actionBtn: {
    padding: 4
  },
  activeIcon: {
    fill: theme.statusPalette.active
  },
  inactiveIcon: {
    fill: theme.statusPalette.inactive
  }
}));

const ClientsList = () => {
  const classes = useStyles();

  const [addressesDialogProps, setAddressesDialogProps] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('rowsPerPage') || 10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [refresh, setRefresh] = useState(false);

  const [clientData, setClientData] = useState(null);

  const [confirmDialogProps, setConfirmDialogProps] = useState(null);

  const { loading, data } = useFetch(
    `${BACKEND_URL}/customers?page=${page}&limit=${rowsPerPage}&q=${search}&${refresh}`
  );

  const columns = useMemo(
    () => [
      { id: 'phone', label: 'Telefon', cls: classes.phoneCell },
      { id: 'name', label: 'Nume' },
      { id: 'description', label: 'Descriere' },
      {
        id: 'has_dispatcher_account',
        label: 'Dispecerat',
        cls: classes.smallCell,
        format: (isTrue) => (isTrue ? 'Da' : 'Nu')
      },
      {
        id: 'has_mobile_account',
        label: 'Mobile',
        cls: classes.smallCell,
        format: (isTrue) => (isTrue ? 'Da' : 'Nu')
      }
    ],
    [classes]
  );

  const deleteClient = useCallback(
    async (id) => {
      try {
        await request(`${BACKEND_URL}/customers/${id}`, {
          method: 'DELETE'
        });

        // Reset page if there are no results and the page is not the first one
        if (data.data.length === 1 && page > 1) {
          setPage(1);
        } else {
          setRefresh((oldValue) => !oldValue);
        }

        setConfirmDialogProps(null);
        NotificationManager.success(`Șters cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmDialogProps, setRefresh, data, page, setPage]
  );

  const deactivateClient = useCallback(
    async (id, active) => {
      try {
        await request(`${BACKEND_URL}/customers/${id}/active/toggle`, {
          method: 'PUT'
        });

        setRefresh((oldValue) => !oldValue);
        setConfirmDialogProps(null);
        NotificationManager.success(`${active ? 'Dezactivat' : 'Activat'} cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setConfirmDialogProps, setRefresh]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage + 1);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      localStorage.setItem('rowsPerPage', +event.target.value);
      setRowsPerPage(+event.target.value);
      setPage(1);
    },
    [setRowsPerPage, setPage]
  );

  const handleAddressesClick = useCallback(
    ({ id, name, phone }) => {
      setAddressesDialogProps({ clientId: id, clientName: name, clientPhone: phone });
    },
    [setAddressesDialogProps]
  );

  const handleClientEdit = useCallback(
    (client) => {
      setClientData(client);
    },
    [setClientData]
  );

  return (
    <Paper classes={{ root: classes.paper }}>
      {loading && <OverlayLoader />}

      {confirmDialogProps && <ConfirmationModal onClose={() => setConfirmDialogProps(null)} {...confirmDialogProps} />}

      {clientData && <ClientFormDialog {...clientData} onClose={() => setClientData(null)} setRefresh={setRefresh} />}

      <Box display="flex" p={0.5} alignItems="center">
        <SearchField
          className={classes.search}
          handleSearch={(val) => {
            setSearch(val);
            setPage(1);
          }}
        />
      </Box>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>
              {columns.map(({ id, cls, label, format, children, ...rest }) => (
                <TableCell key={id} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {children || label}
                </TableCell>
              ))}

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Adrese
              </TableCell>

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Editare
              </TableCell>

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Status
              </TableCell>

              <TableCell classes={{ root: clsx(classes.cell, classes.smallCell), head: classes.headCell }}>
                Ștergere
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.data.map(({ blacklisted_drivers, customer }) => (
                <TableRow key={customer.id}>
                  {columns.map(({ id, format, cls }) => {
                    const value = customer[id];

                    return (
                      <TableCell key={id} classes={{ root: clsx(classes.cell, cls) }}>
                        {format ? format(value) : value || '-'}
                      </TableCell>
                    );
                  })}

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <IconButton className={classes.actionBtn} onClick={() => handleAddressesClick(customer)}>
                      <EditLocationIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={() => handleClientEdit({ ...customer, blacklisted_drivers })}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={() =>
                        setConfirmDialogProps({
                          confirmCallback: () => deactivateClient(customer.id, customer.is_active),
                          message: `${customer.is_active ? 'Dezactivezi' : 'Activezi'} clientul ${
                            customer.name || '-'
                          } / ${customer.phone || '-'} ?`
                        })
                      }
                    >
                      {customer.is_active ? (
                        <CheckCircleIcon className={classes.activeIcon} />
                      ) : (
                        <CancelIcon className={classes.inactiveIcon} />
                      )}
                    </IconButton>
                  </TableCell>

                  <TableCell classes={{ root: clsx(classes.cell, classes.smallCell) }}>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={() =>
                        setConfirmDialogProps({
                          confirmCallback: () => deleteClient(customer.id),
                          message: `Ștergi clientul ${customer.name || '-'} / ${customer.phone || '-'} ?`
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage="Rânduri pe pagină:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`}
        classes={{ root: classes.paginationToolbar }}
        component="div"
        count={data ? data.total : 0}
        rowsPerPage={rowsPerPage}
        page={data ? page - 1 : 0}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />

      {addressesDialogProps && (
        <ClientAddressesDialog onClose={() => setAddressesDialogProps(null)} {...addressesDialogProps} />
      )}
    </Paper>
  );
};

export default ClientsList;
