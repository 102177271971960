// React
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';

// CSS
import './index.css';

// Components
import App from './components/App';

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';

// Utils
import * as serviceWorker from './utils/serviceWorker';
import history from './utils/history';

Sentry.init({ dsn: 'https://45c363781f1442b8bc57279650985a69@o410186.ingest.sentry.io/5283996' });

ReactDOM.render(
  <Router history={history}>
    <CssBaseline>
      <App />
    </CssBaseline>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
