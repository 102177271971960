/* Custom theme for the app */

// Material UI
import { createMuiTheme } from '@material-ui/core/styles';

// Constants
import { ACCEPTED, CANCELED, COMPLETED, PENDING } from '../components/Routes/constants';
import { ANSWERED_STATUS, HOLDED_STATUS, NEW_STATUS, CANCELED_DRIVER } from '../constants';

const statusPalette = {
  light: {
    activeDriver: '#188a1f',
    penalizedDriver: '#ff272d',
    active: '#00bf46',
    inactive: '#ff6162',
    connected: '#00bf46',
    disconnected: '#ff6162',
    [NEW_STATUS]: '#6eb1ff',
    [CANCELED_DRIVER]: '#6eb1ff',
    [HOLDED_STATUS]: '#ff9722',
    [ANSWERED_STATUS]: '#00bf46',
    [PENDING]: '#ff9722',
    [ACCEPTED]: '#00bf46',
    [CANCELED]: '#ff6162',
    [COMPLETED]: '#595959',
    clientAddress: '#00bf46',
    customAddress: '#ff9722'
  },
  dark: {
    activeDriver: '#188a1f',
    penalizedDriver: '#ff272d',
    active: '#9ccc6c',
    inactive: '#ff6555',
    connected: '#9ccc6c',
    disconnected: '#ff6555',
    [NEW_STATUS]: '#b1c8ff',
    [CANCELED_DRIVER]: '#b1c8ff',
    [HOLDED_STATUS]: '#ffd35c',
    [ANSWERED_STATUS]: '#9ccc6c',
    [PENDING]: '#ffd35c',
    [ACCEPTED]: '#9ccc6c',
    [CANCELED]: '#ff6555',
    [COMPLETED]: '#c1c1c1',
    clientAddress: '#9ccc6c',
    customAddress: '#ffd35c'
  }
};

// Create app theme and include some extra configs for charts
const createTheme = (type) => {
  const isDark = type === 'dark';

  return createMuiTheme({
    palette: {
      type,
      primary: {
        main: isDark ? '#ffffff' : '#000000'
      },
      secondary: {
        main: isDark ? '#ffffff' : '#000000'
      },
      action: {
        disabledBackground: 'rgba(0, 0, 0, 0.2)'
      }
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 12,
          textAlign: 'center'
        }
      },
      MuiInputLabel: {
        asterisk: {
          color: '#f44336'
        }
      },
      MuiSwitch: {
        track: {
          '$checked$checked + &': {
            opacity: 1,
            backgroundColor: isDark ? '#9ccc6c' : '#00bf46'
          }
        }
      }
    },
    table: {
      headerBackground: isDark ? '#303030' : '#e7e7e7',
      cellPadding: '4px 16px',
      smCellPadding: '0px 12px'
    },
    statusPalette: statusPalette[type]
  });
};

export default createTheme;
