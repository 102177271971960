/** Search field */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  searchInput: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 30
  },
  clearIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 5,
    fontSize: '1.2rem'
  }
});

const SearchField = ({ handleSearch, ...rest }) => {
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  function clearSearch() {
    setSearchValue('');
    handleSearch('');
  }

  return (
    <div {...rest}>
      <div className={classes.root}>
        <TextField
          variant="outlined"
          placeholder="Cautare"
          value={searchValue}
          classes={{ root: classes.searchRoot }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              /* trigger search on Rnter key */
              handleSearch(e.target.value);
            } else if (e.keyCode === 27) {
              /* clear the search on Exc key */
              clearSearch();
            }
          }}
          InputProps={{ classes: { input: classes.searchInput } }}
        />
        {searchValue && (
          <ClearIcon
            className={classes.clearIcon}
            onClick={() => {
              clearSearch();
            }}
          />
        )}
      </div>
    </div>
  );
};

SearchField.propTypes = {
  handleSearch: PropTypes.func.isRequired
};

export default SearchField;
