// React
import React, { useContext, useState } from 'react';

// Material-ui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import request from '../../../utils/request';
import { AuthContext } from '../../../context';
import getQueryString from '../../../utils/getQueryString';

// Constants
import { ANSWERED_STATUS, BACKEND_URL, HOLDED_STATUS, NEW_STATUS } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  ringBtn: {
    backgroundColor: theme.statusPalette[NEW_STATUS],
    margin: 10
  },
  answerBtn: {
    backgroundColor: theme.statusPalette[ANSWERED_STATUS],
    margin: 10
  },
  holdBtn: {
    backgroundColor: theme.statusPalette[HOLDED_STATUS],
    margin: 10
  },
  hangupBtn: {
    backgroundColor: 'gray',
    margin: 10
  },
  input: {
    MozAppearance: 'textfield',
    WebkitAppearance: 'none',
    margin: 0
  }
}));

let line = 1;

const DemoCalls = () => {
  const classes = useStyles();

  const { interior } = useContext(AuthContext);

  const [phone, setPhone] = useState();
  const [error, setError] = useState(false);

  const handleCall = async (status) => {
    if (!phone) {
      setError('Adaugă un telefon');
      return;
    }

    if (!interior) {
      setError('Autentifică-te cu un interior valid');
      return;
    }

    setError(null);

    try {
      await request(`https://backend.hunedoara.rambit.ro/v2/api/call/update`, {
        method: 'POST',
        body: getQueryString({
          caller_number: phone,
          call_status: status,
          extension_number: interior + line
        })
      });

      line++;
    } catch (error) {}
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" pt={4}>
      <TextField
        label="Telefon"
        type="number"
        classes={{ input: classes.input }}
        error={!!error}
        helperText={error}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      <div>
        <Button className={classes.ringBtn} onClick={() => handleCall('ring')}>
          Sună
        </Button>

        <Button className={classes.answerBtn} onClick={() => handleCall('answer')}>
          Răspunde
        </Button>

        <Button className={classes.holdBtn} onClick={() => handleCall('hold')}>
          În așteptare
        </Button>

        <Button className={classes.hangupBtn} onClick={() => handleCall('hangup')}>
          Închide
        </Button>
      </div>
    </Box>
  );
};

export default DemoCalls;
