/* AuthButton - custom styled button for auth layouts */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: '#ffffff',

    '&$disabled': {
      color: 'rgba(255, 255, 255, 0.26)'
    }
  },
  disabled: {}
});

function AuthTextfield({ text, ...rest }) {
  const classes = useStyles();

  return (
    <Button classes={{ root: classes.root, disabled: classes.disabled }} fullWidth size="large" type="submit" {...rest}>
      {text}
    </Button>
  );
}

AuthTextfield.propTypes = {
  text: PropTypes.node.isRequired
};

export default AuthTextfield;
