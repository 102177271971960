// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import BlockIcon from '@material-ui/icons/Block';
import { makeStyles } from '@material-ui/core/styles';

// Utils
import moment from 'moment';
import clsx from 'clsx';

// Constants
import { DATE_TIME_FORMAT } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  blockIcon: {
    fill: theme.statusPalette.penalizedDriver
  },
  penalizedIcon: {
    fill: theme.statusPalette.penalizedDriver
  },
  pendingPenaltyIcon: {
    fill: '#ffd340'
  }
}));

const PenaltyIcon = ({
  user: { indicative, username, penalization_starts_at: start, penalization_ends_at: end, id },
  onClick
}) => {
  const classes = useStyles();

  if (!indicative) {
    return '-';
  }

  const startDate = moment(start || undefined);
  const endDate = moment(end || undefined);
  const currentDate = moment();

  if (currentDate.isSameOrAfter(startDate) && currentDate.isSameOrAfter(endDate)) {
    return (
      <IconButton
        onClick={() =>
          onClick({
            id,
            indicative,
            username,
            penalized: false,
            end,
            start
          })
        }
      >
        <BlockIcon className={classes.blockIcon} />
      </IconButton>
    );
  }

  const startDateFormatted = startDate.format(DATE_TIME_FORMAT);
  const endDateFormatted = endDate.format(DATE_TIME_FORMAT);

  const isPenalized = currentDate.isBetween(startDate, endDate);
  const tooltip = isPenalized
    ? `Șoferul este penalizat de la ${startDateFormatted} până la ${endDateFormatted}`
    : `Șoferul o să fie penalizat de la ${startDateFormatted} până la ${endDateFormatted}`;

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={() => onClick({ id, indicative, penalized: true, end, start })}>
        <WarningIcon
          className={clsx(isPenalized && classes.penalizedIcon, !isPenalized && classes.pendingPenaltyIcon)}
        />
      </IconButton>
    </Tooltip>
  );
};

PenaltyIcon.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    penalization_ends_at: PropTypes.string.isRequired,
    penalization_starts_at: PropTypes.string.isRequired,
    indicative: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
  })
};

export default PenaltyIcon;
