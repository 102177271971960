// React
import React, { useCallback, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';

// Components
import Map from './Map';
import List from './List';
import Form from './Form';

// Formik
import { Formik } from 'formik';
import { validationSchema } from './validation';
import request from '../../../utils/request';
import { BACKEND_URL } from '../../../constants';
import { NotificationManager } from 'react-notifications';

const initialValues = {
  address: '',
  latitude: '',
  longitude: ''
};

const Addresses = () => {
  const [pinLocation, setPinLocation] = useState(null);

  // Used to show the current edited address
  const [addressInEdit, setAddressInEdit] = useState(null);

  // Used to set map zoom & center when the address is edited from the list
  const [changedFromList, setChangedFromList] = useState(false);

  // Force list refresh by changing this bool
  const [refresh, setRefresh] = useState(false);

  const handleSubmit = useCallback(
    async ({ id, ...values }, { resetForm }) => {
      try {
        const url = id ? `${BACKEND_URL}/address/${id}/custom` : `${BACKEND_URL}/addresses/custom`;
        const method = id ? 'PUT' : 'POST';

        await request(url, {
          method: method,
          body: JSON.stringify({ ...values })
        });

        resetForm();
        setPinLocation(null);
        setAddressInEdit(null);
        setRefresh((old) => !old);

        NotificationManager.success(`Adresa a fost salvată cu succes!`);
      } catch (ex) {
        NotificationManager.error(`A apărut o eroare ${ex.message}`);
      }
    },
    [setRefresh]
  );

  return (
    <Box height="100%" display="flex">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, isValid, setValues, resetForm, values }) => {
          const handleLocationChange = (adr, changedFromList) => {
            setChangedFromList(!!changedFromList);
            setPinLocation({ lat: adr.latitude, lng: adr.longitude });

            if (changedFromList) {
              setValues(adr);
              setAddressInEdit(adr.address);
            } else {
              if (values.id) {
                // In edit mode we only need to update the coordinates from the map
                setValues({ ...values, latitude: adr.latitude, longitude: adr.longitude });
              } else {
                // In creation mode we overwrite everything
                setValues(adr);
              }
            }
          };

          const handleResetForm = () => {
            resetForm();
            setPinLocation(null);
            setAddressInEdit(null);
          };

          return (
            <>
              <Box flex={1} pr={1}>
                <Form
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                  isValid={isValid}
                  resetForm={handleResetForm}
                  addressInEdit={addressInEdit}
                />

                <List
                  handleLocationChange={handleLocationChange}
                  resetForm={handleResetForm}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  editedAddressId={values.id}
                />
              </Box>

              <Box flex={1}>
                <Map
                  pinLocation={pinLocation}
                  changedFromList={changedFromList}
                  handleLocationChange={handleLocationChange}
                />
              </Box>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Addresses;
