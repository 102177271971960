// React
import React from 'react';

// Components
import Map from './Map';
import DriversDataContainer from './DriversDataContainer';

const SmallMap = () => <DriversDataContainer>{({ drivers }) => <Map drivers={drivers} />}</DriversDataContainer>;

export default SmallMap;
