import React from 'react';

function SupportPage() {
  return (
    <div style={{ padding: 20 }}>
      <h1>Client Mobyl Taxi Support</h1>

      <p>Pentru a putea utiliza aplicația, acordul GDPR trebuie acceptat.</p>

      <p>
        Pentru nelămuriri sau ajutor suplimentar, ne puteți contacta la adresa de email: <b>contact.rambit@gmail.com</b>
        .
      </p>
    </div>
  );
}

export default SupportPage;
