// Inactive orders list

// React
import React, { useMemo, useState } from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Filters from './Filters';
import OverlayLoader from '../../Loader/OverlayLoader';
import TablePaginationActions from '../../TablePaginationActions';
import StatusCell from './StatusCell';
import OriginIcon from './OriginIcon';
import OrderDetailsDialog from './OrderDetailsDialog';
import ChatHistoryDialog from './ChatHistoryDialog';

// Utils
import clsx from 'clsx';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isBoolean from 'lodash/isBoolean';
import { useFetch } from '../../../utils/request';
import { secondsToMinutes, emitInfoByRadius } from '../../../utils/formatters';
import getQueryString from '../../../utils/getQueryString';
import moment from 'moment';

// Constants
import { COMPLETED, CANCELED } from '../../Routes/constants';
import { BACKEND_URL, DATE_TIME_FORMAT, CANCELED_DRIVER } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  closeIcon: {
    color: theme.statusPalette.inactive
  },
  checkIcon: {
    color: theme.statusPalette.active
  },
  table: {
    minWidth: 650
  },
  cell: {
    padding: theme.table.cellPadding,
    maxWidth: 300,
    wordBreak: 'keep-all'
  },
  [CANCELED_DRIVER]: {
    color: theme.statusPalette[CANCELED_DRIVER]
  },
  [CANCELED]: {
    color: theme.statusPalette[CANCELED]
  },
  [COMPLETED]: {
    color: theme.statusPalette[COMPLETED]
  },
  dateCell: {
    width: 200
  },
  mediumCell: {
    width: 120
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  actionBtn: {
    padding: 6
  },
  headCell: {
    backgroundColor: theme.table.headerBackground,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  paginationToolbar: {
    overflow: 'hidden',
    minHeight: 52
  }
}));

const OrdersHistory = () => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        col: 'from_mobile_app',
        label: 'Origine',
        cls: classes.smallCell,
        format: (isMobile) => <OriginIcon isMobile={isMobile} />,
        sortable: false
      },
      { col: 'line', label: 'Linie', cls: classes.smallCell, align: 'center', sortable: true },
      { col: 'interior', label: 'Consola', cls: classes.smallCell, align: 'center', sortable: true },
      { col: 'address', label: 'Adresa', sortable: true },
      { col: 'details', label: 'Detalii', sortable: true },
      { col: 'name', label: 'Client', sortable: true },
      { col: 'phone', label: 'Telefon', cls: classes.mediumCell, sortable: true },
      {
        col: 'created_at',
        label: 'Data',
        cls: classes.dateCell,
        format: (date) => moment(date).format(DATE_TIME_FORMAT),
        sortable: true
      },
      {
        col: 'nr_of_emits',
        cls: classes.smallCell,
        label: 'Preluare',
        format: emitInfoByRadius,
        sortable: true
      },
      {
        col: 'process_seconds',
        label: 'Timp procesare',
        cls: classes.smallCell,
        format: secondsToMinutes,
        sortable: true
      },
      {
        col: 'nr_of_cars',
        label: 'Nr. mașini',
        cls: classes.smallCell,
        sortable: true
      },
      { col: 'indicative', label: 'Mașina', cls: classes.smallCell, align: 'center', sortable: true },
      { col: 'driver_username', label: 'Utilizator', cls: classes.mediumCell, align: 'center' },
      {
        col: 'status',
        cls: classes.mediumCell,
        label: 'Status',
        format: (status, { reason }) => <StatusCell status={status} reason={reason} />,
        sortable: true
      }
    ],
    [classes]
  );

  const [chatDialogProps, setChatDialogProps] = useState(null);
  const [detailsDialogProps, setDetailsDialogProps] = useState(null);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('s_created_at');

  const [filters, setFilters] = useState({
    q_created_at_start: moment().startOf('day').toISOString(),
    q_created_at_end: moment().toISOString()
  });

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(+localStorage.getItem('rowsPerPage') || 10);

  const fetchParams = {
    ...pickBy(filters, identity),
    page,
    limit: rowsPerPage,
    [orderBy]: order
  };

  if (isBoolean(filters.q_from_mobile_app)) {
    fetchParams.q_from_mobile_app = filters.q_from_mobile_app;
  }

  const { loading, data } = useFetch(`${BACKEND_URL}/orders?${getQueryString(fetchParams)}`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const createSortHandler = (col) => () => {
    const orderByCol = `s_${col}`;
    const isAsc = orderBy === orderByCol && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(orderByCol);
  };

  return (
    <Paper classes={{ root: classes.paper }}>
      {loading && <OverlayLoader />}

      {detailsDialogProps && <OrderDetailsDialog onClose={() => setDetailsDialogProps(null)} {...detailsDialogProps} />}

      {chatDialogProps && <ChatHistoryDialog onClose={() => setChatDialogProps(null)} {...chatDialogProps} />}

      <Filters applyFilters={setFilters} setPage={setPage} />

      <TableContainer>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(({ col, label, cls, format, sortable, ...rest }) => (
                <TableCell key={col} classes={{ root: clsx(classes.cell, cls), head: classes.headCell }} {...rest}>
                  {sortable ? (
                    <TableSortLabel
                      active={orderBy === `s_${col}`}
                      direction={orderBy === `s_${col}` ? order : 'asc'}
                      onClick={createSortHandler(col)}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              ))}

              <TableCell classes={{ root: classes.smallCell, head: classes.headCell }}>Conversație</TableCell>

              <TableCell classes={{ root: classes.smallCell, head: classes.headCell }}>Detalii</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.data.map((order) => (
                <TableRow key={order.id}>
                  {columns.map(({ col, format, cls, sortable, ...rest }) => {
                    const value = order[col];

                    return (
                      <TableCell
                        key={col}
                        classes={{ root: clsx(classes.cell, classes[order.reason] || classes[order.status], cls) }}
                        {...rest}
                      >
                        {format ? format(value, order) : value ?? '-'}
                      </TableCell>
                    );
                  })}

                  <TableCell
                    classes={{
                      root: clsx(classes.smallCell, classes.actionCell, classes[order.reason] || classes[order.status])
                    }}
                  >
                    {order.from_mobile_app ? (
                      <IconButton
                        classes={{ root: clsx(classes.actionBtn, classes[order.reason] || classes[order.status]) }}
                        onClick={() => setChatDialogProps({ orderId: order.id })}
                      >
                        <QuestionAnswerIcon />
                      </IconButton>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell classes={{ root: clsx(classes.smallCell, classes.actionCell) }}>
                    <IconButton
                      classes={{ root: clsx(classes.actionBtn, classes[order.reason] || classes[order.status]) }}
                      onClick={() => {
                        const orderDate = moment(order.created_at);
                        setDetailsDialogProps({
                          created_at: order.created_at,
                          address: order.address,
                          nr_of_emits: order.nr_of_emits,
                          status: order.status,
                          reason: order.reason,
                          indicative: order.indicative,
                          b_nr_of_emits: order.b_nr_of_emits,
                          drivers: order.drivers_joined_array.map((d) => {
                            const wasPenalized =
                              d.penalization_starts_at &&
                              d.penalization_ends_at &&
                              orderDate.isAfter(moment(d.penalization_starts_at)) &&
                              orderDate.isBefore(moment(d.penalization_ends_at));
                            return {
                              id: d.id,
                              logged_in: d.logged_in,
                              indicative: d.indicative,
                              last_location_updated_at: d.last_location_updated_at,
                              last_order_taken: d.last_order_taken,
                              priority_level: d.priority_level,
                              wasPenalized,
                              distance_to_order: d.distance_to_order,
                              nr_of_orders_in_shift: d.nr_of_orders_in_shift
                            };
                          })
                        });
                      }}
                    >
                      <HelpIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        labelRowsPerPage="Rânduri pe pagină:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} din ${count}`}
        classes={{ root: classes.paginationToolbar }}
        component="div"
        count={data ? data.total : 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default OrdersHistory;
