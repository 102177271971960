// React
import React from 'react';
import PropTypes from 'prop-types';

// Material-ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

// Formik
import { Formik, Form } from 'formik';

// Utils
import request, { useFetch } from '../../../utils/request';
import clientValidation from './validation';
import { NotificationManager } from 'react-notifications';
import isEqual from 'lodash/isEqual';

// Components
import CustomTextField from '../../Form/CustomTextField';
import OverlayLoader from '../../Loader/OverlayLoader';

// Constants
import { BACKEND_URL } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  left: {
    marginRight: theme.spacing(2)
  },
  right: {
    marginLeft: theme.spacing(2)
  }
}));

const ClientFormDialog = ({
  id,
  phone,
  name = '',
  description = '',
  blacklisted_drivers = [],
  onClose,
  setRefresh
}) => {
  const classes = useStyles();

  const { loading, data: drivers } = useFetch(`${BACKEND_URL}/drivers/autocomplete`);

  if (loading) {
    return <OverlayLoader fullScreen />;
  }

  const saveClient = async ({ blacklisted_drivers, ...customer }, { setSubmitting }) => {
    try {
      await request(`${BACKEND_URL}/customers/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ blacklisted_drivers, customer })
      });

      NotificationManager.success('Client salvat cu success!');

      setSubmitting(false);
      setRefresh((r) => !r);
      onClose();
    } catch (ex) {
      setSubmitting(false);
      NotificationManager.error(`A apărut o eroare ${ex.message}`);
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <Formik
        validationSchema={clientValidation}
        initialValues={{ phone, name, description, blacklisted_drivers }}
        onSubmit={saveClient}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, values }) => (
          <Form>
            <DialogTitle>Editare client</DialogTitle>

            <DialogContent>
              <Box display="flex">
                <CustomTextField disabled className={classes.left} label="Telefon" required name="phone" />

                <CustomTextField autoFocus className={classes.right} label="Nume" name="name" />
              </Box>

              <Box>
                <CustomTextField label="Descriere" name="description" />
              </Box>

              <Box>
                <Autocomplete
                  multiple
                  id="blacklist"
                  options={drivers}
                  filterSelectedOptions
                  disableCloseOnSelect
                  value={values.blacklisted_drivers}
                  getOptionLabel={({ indicative, username }) => `${indicative} - ${username}`}
                  getOptionSelected={(option, v) => isEqual(v, option)}
                  onChange={(e, value) => setFieldValue('blacklisted_drivers', value)}
                  renderInput={(params) => <TextField {...params} margin="normal" label="Șoferi blocați" />}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Renunță
              </Button>

              <Button type="submit" color="primary" disabled={isSubmitting || !dirty || !isValid}>
                Salvează
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

ClientFormDialog.propTypes = {
  id: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  blacklisted_drivers: PropTypes.array.isRequired
};

export default ClientFormDialog;
