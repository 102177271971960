// Public routes
export const LOGIN_ROUTE = '/login';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const CHANGE_PASSWORD_ROUTE = '/change-password/:code';
export const PRIVACY_ROUTE = '/privacy';
export const SUPPORT_ROUTE = '/support';
export const PUBLIC_ROUTES = [LOGIN_ROUTE, FORGOT_PASSWORD_ROUTE, CHANGE_PASSWORD_ROUTE, PRIVACY_ROUTE, SUPPORT_ROUTE];

// Private routes
export const DASHBOARD_ROUTE = '/';
export const ADMIN_DASHBOARD_ROUTE = '/admin';
export const STATISTICS_ROUTE = '/statistics';
export const USERS_ROUTE = '/users';
export const CLIENTS_ROUTE = '/clients';
export const ADDRESSES_ROUTE = '/adrese';
export const ORDERS_HISTORY_ROUTE = '/orders-history';
export const SETTINGS_ROUTE = '/settings';
export const MAP_ROUTE = '/map';
export const DEMO_CALLS_ROUTE = '/calls';

// Order statuses
export const PENDING = 'PENDING';
export const PENDING_DIRECT_ALLOCATION = 'PENDING_DIRECT_ALLOCATION';
export const ACCEPTED = 'ACCEPTED';
export const CANCELED = 'CANCELED';
export const COMPLETED = 'COMPLETED';
export const DELAYED_TAKEN = 'DELAYED_TAKEN';

export const STATUSES = {
  [PENDING]: 'În așteptare...',
  [ACCEPTED]: 'Acceptată',
  [CANCELED]: 'Anulată',
  [COMPLETED]: 'Finalizată'
};
