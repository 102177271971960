/** Loader with overlay container, centered on first positioned parent or fullscreen */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

// Components
import Loader from './Loader';

const OverlayLoader = ({ fullScreen = false, loaderSize, background, text }) => {
  const theme = useTheme();

  return (
    <Box
      position={fullScreen ? 'fixed' : 'absolute'}
      top={0}
      left={0}
      height="100%"
      width="100%"
      zIndex={1250}
      style={{ backgroundColor: background || 'rgba(0, 0, 0, 0.4)', color: theme.palette.primary.main }}
    >
      <Loader size={loaderSize} color="inherit" text={text} />
    </Box>
  );
};

OverlayLoader.propTypes = {
  fullScreen: PropTypes.bool,
  loaderSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  text: PropTypes.string
};

export default OverlayLoader;
