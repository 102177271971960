// React
import React, { useContext, useEffect, useState, useCallback } from 'react';

// Constants
import { SOCKET_URL } from '../../../constants';

// Utils
import { AuthContext, SocketContext } from '../../../context';
import WsClient from './socket';

// Actions
import { logoutSuccess } from '../../App/actions';

const createConnection = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return new WsClient(`${SOCKET_URL}?token=${token}`);
  }
  return null;
};

export default function SocketProvider({ children }) {
  const { dispatch } = useContext(AuthContext);

  const [socket, setSocket] = useState(null);

  const [retries, setRetries] = useState(1);

  const [connected, setConnected] = useState(false);

  const handleConnectionError = useCallback((err) => {
    console.log('Socket error: ', err.message || err);
  }, []);

  const handleConnectionOpen = useCallback(() => {
    console.log('Connected !');
    setConnected(true);
    setRetries(1);
  }, [setConnected, setRetries]);

  const handleConnectionClose = useCallback(
    (e) => {
      console.log(`Disconnected - ${e.code}!`);
      setConnected(false);

      if (e.code === 4000) {
        // JWT expirat sau inactivare/stergere user
        dispatch(logoutSuccess());
      } else if (e.code !== 1000) {
        setRetries((r) => r + 1);
      }
    },
    [setConnected, setRetries, dispatch]
  );

  const setupListeners = useCallback(
    (socketConnection) => {
      socketConnection.on('error', handleConnectionError);
      socketConnection.on('open', handleConnectionOpen);
      socketConnection.on('close', handleConnectionClose);
    },
    [handleConnectionError, handleConnectionOpen, handleConnectionClose]
  );

  const clearListeners = useCallback(
    (socketConnection) => {
      socketConnection.off('error', handleConnectionError);
      socketConnection.off('open', handleConnectionOpen);
      socketConnection.off('close', handleConnectionClose);
    },
    [handleConnectionError, handleConnectionOpen, handleConnectionClose]
  );

  useEffect(() => {
    if (!socket) {
      const socketConnection = createConnection();

      if (socketConnection) {
        setSocket(socketConnection);
        setupListeners(socketConnection);
      } else {
        dispatch(logoutSuccess());
      }
    }

    return () => {
      if (socket) {
        clearListeners(socket);
      }
    };
  }, [socket, setSocket, dispatch, setupListeners, clearListeners]);

  return <SocketContext.Provider value={{ socket, retries, connected }}>{children}</SocketContext.Provider>;
}
