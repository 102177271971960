// React
import React, { useState, useCallback } from 'react';

export const LocationContext = React.createContext();

export default function LocationProvider({ children }) {
  const [location, setLocation] = useState(null);
  const [updatedFromMap, setUpdatedFromMap] = useState(false);

  const updateLocation = useCallback(
    (l, isFromMap = false) => {
      setUpdatedFromMap(isFromMap);
      setLocation(l);
    },
    [setLocation, setUpdatedFromMap]
  );

  return (
    <LocationContext.Provider value={{ updatedFromMap, location, setLocation: updateLocation, setUpdatedFromMap }}>
      {children}
    </LocationContext.Provider>
  );
}
