/* Checkbox - formik wrapped checkbox */

// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

// Formik
import { useField } from 'formik';

function Checkbox({ label, name, formControlLabelProps, ...rest }) {
  const [field] = useField(name);

  return (
    <FormControlLabel
      {...formControlLabelProps}
      control={<MuiCheckbox {...field} checked={field.value} {...rest} />}
      label={label}
    />
  );
}

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  formControlLabelProps: PropTypes.object
};

export default Checkbox;
