/** Private route that redirects to login if the user is not authenticated */

// React
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// Constants
import { LOGIN_ROUTE } from './constants';

const PrivateRoute = ({ authenticated, Component, ...rest }) => (
  <Route
    {...rest}
    render={() => (localStorage.getItem('token') && authenticated ? Component : <Redirect to={LOGIN_ROUTE} />)}
  />
);

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  Component: PropTypes.any.isRequired
};

export default PrivateRoute;
